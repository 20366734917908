var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { tw } from 'twind';
import { ModalHeader, ModalFooter } from './BaseModal';
import useModal from '../../hooks/useModal';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Colors, Styles } from '../../constants/constants-styles';
import { IoBuild } from 'react-icons/io5';
import useTheme from '../../hooks/useTheme';
import useParse from '../../hooks/useParse';
import Button from '../Button';
import { useEffect, useState } from 'react';
import useAnnouncements from '../../hooks/useAnnouncements';
export var AnnouncementModal = function (_a) {
    var _b;
    var announcementQuery = _a.announcement, _c = _a.closeToList, closeToList = _c === void 0 ? false : _c;
    var modal = useModal();
    var theme = useTheme();
    var announcements = useAnnouncements().announcements;
    var authenticated = useParse().authenticated;
    var _d = useState(), announcement = _d[0], setAnnouncement = _d[1];
    useEffect(function () {
        if (typeof announcementQuery === 'string') {
            setAnnouncement(announcements.find(function (_a) {
                var id = _a.id;
                return id === announcementQuery;
            }));
        }
        else {
            setAnnouncement(announcementQuery);
        }
    }, [announcementQuery]);
    return (_jsxs(_Fragment, { children: [!announcement && _jsx(ModalHeader, { title: "Loading Announcement..." }, void 0), announcement && (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { title: announcement.attributes.title, actions: [
                            {
                                icon: IoBuild,
                                className: tw(authenticated ? "" : "hidden"),
                                onClick: function () {
                                    modal.show({
                                        name: 'changeAnnouncement',
                                        props: { announcement: announcement },
                                    });
                                },
                            },
                        ], subtitle: (function () {
                            var createdAt = announcement.createdAt, updatedAt = announcement.updatedAt;
                            dayjs.extend(relativeTime);
                            var subtitle = [
                                "created " + dayjs(createdAt.toString()).from(new Date()),
                            ];
                            if (!dayjs(createdAt).isSame(updatedAt, 'day')) {
                                subtitle.push("updated " + dayjs(updatedAt.toString()).from(new Date()));
                            }
                            return subtitle;
                        })() }, void 0), _jsxs("div", __assign({ className: tw("flex flex-col space-y-8") }, { children: [_jsx("span", __assign({ className: tw("text-[" + theme.text + "]") }, { children: (_b = announcement.attributes.description) !== null && _b !== void 0 ? _b : 'No Description' }), void 0), _jsx("div", __assign({ className: tw("flex flex-col space-y-4") }, { children: announcement.attributes.links && (_jsxs("div", __assign({ className: tw("flex flex-col") }, { children: [_jsx("h2", __assign({ className: tw(Styles.font.body, "text-xl font-bold text-[" + theme.text + "]") }, { children: "Links" }), void 0), _jsx("div", __assign({ className: tw("flex flex-col w-3/4") }, { children: announcement.attributes.links.map(function (link) { return (_jsx("a", __assign({ href: link, className: tw(Styles.font.inter, "text-[" + Colors.primary + "] text-underline") }, { children: link }), void 0)); }) }), void 0)] }), void 0)) }), void 0)] }), void 0)] }, void 0)), _jsx(ModalFooter, { children: _jsx(Button, __assign({ onClick: function () {
                        if (closeToList) {
                            modal.show({
                                name: 'announcementList',
                                props: {},
                            });
                        }
                        else {
                            modal.hide();
                        }
                    } }, { children: closeToList ? 'Back to Announcements' : 'Close' }), void 0) }, void 0)] }, void 0));
};
export default AnnouncementModal;
