var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { tw } from 'twind';
import Icon from '../Icon';
import { Transition } from '@headlessui/react';
export var BaseToast = function (_a) {
    var children = _a.children, theme = _a.theme, className = _a.className, _b = _a.iconName, iconName = _b === void 0 ? 'notification' : _b, iconClassName = _a.iconClassName, onClick = _a.onClick;
    return (_jsxs(Transition, __assign({ as: "button", show: true, appear: true, enter: tw(templateObject_1 || (templateObject_1 = __makeTemplateObject(["ease-out"], ["ease-out"]))), enterFrom: tw(templateObject_2 || (templateObject_2 = __makeTemplateObject(["transition-all -translate-y-16"], ["transition-all -translate-y-16"]))), enterTo: tw(templateObject_3 || (templateObject_3 = __makeTemplateObject(["transition-all translate-y-0"], ["transition-all translate-y-0"]))), className: tw("rounded-xl shadow-md px-4 py-2", "flex items-center justify-between space-x-8", className !== null && className !== void 0 ? className : tw(templateObject_4 || (templateObject_4 = __makeTemplateObject(["bg-[", "] text-[", "]"], ["bg-[", "] text-[", "]"])), theme.page, theme.text)), onClick: onClick }, { children: [_jsx(Icon, { as: iconName, className: iconClassName !== null && iconClassName !== void 0 ? iconClassName : tw("text-[" + theme.primary + "]") }, void 0), children] }), void 0));
};
export default BaseToast;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
