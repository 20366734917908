var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParse } from './useParse';
import useInterval from '@use-it/interval';
import { PlaceResyncDelay } from '../constants/constants-general';
import Parse from 'parse';
import Fuse from 'fuse.js';
export var DefaultViewport = {
    latitude: 43.73418189028605,
    longitude: -79.41167184549289,
    width: '100vw',
    height: '70vh',
    zoom: 12,
};
var MapContext = React.createContext({
    viewport: DefaultViewport,
    places: [],
    setViewport: function () { },
    searchPlaces: new Fuse([]),
});
export var useMap = function () { return React.useContext(MapContext); };
export var MapProvider = function (_a) {
    var children = _a.children;
    var _b = useParse(), initialized = _b.initialized, getPlaces = _b.getPlaces;
    var _c = useState(DefaultViewport), viewport = _c[0], setViewport = _c[1];
    var _d = useState(DefaultViewport), lastViewport = _d[0], setLastViewport = _d[1];
    var _e = useState(), places = _e[0], setPlaces = _e[1];
    var _f = useState(false), isRunning = _f[0], setIsRunning = _f[1];
    var searchPlaces = useMemo(function () {
        return new Fuse(places !== null && places !== void 0 ? places : [], {
            keys: ['attributes.name'],
            includeScore: true,
        });
    }, [places]);
    var resyncPlaces = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!(viewport.latitude !== lastViewport.latitude ||
                        viewport.longitude !== lastViewport.longitude)) return [3 /*break*/, 2];
                    _a = setPlaces;
                    _b = [__spreadArray([], (places !== null && places !== void 0 ? places : []))];
                    return [4 /*yield*/, getPlaces({
                            query: {
                                near: new Parse.GeoPoint({
                                    longitude: viewport.longitude,
                                    latitude: viewport.latitude,
                                }),
                            },
                        })];
                case 1:
                    _a.apply(void 0, [__spreadArray.apply(void 0, _b.concat([(_c.sent())]))]);
                    setLastViewport(viewport);
                    _c.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [places, viewport, lastViewport, getPlaces]);
    var initializePlaces = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var fetchedPlaces;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getPlaces({
                        query: {
                            near: new Parse.GeoPoint({
                                latitude: viewport.latitude,
                                longitude: viewport.longitude,
                            }),
                        },
                    })];
                case 1:
                    fetchedPlaces = _a.sent();
                    setPlaces(fetchedPlaces);
                    return [2 /*return*/];
            }
        });
    }); }, [viewport.latitude, viewport.longitude, getPlaces]);
    useEffect(function () {
        if (initialized && !places) {
            initializePlaces();
            setIsRunning(true);
            setPlaces([]);
        }
    }, [initialized, places, initializePlaces]);
    useEffect(function () {
        return function () { return setIsRunning(false); };
    }, []);
    useInterval(function () { return resyncPlaces(); }, isRunning ? 1000 * PlaceResyncDelay : null);
    return (_jsx(MapContext.Provider, __assign({ value: {
            viewport: viewport,
            places: places !== null && places !== void 0 ? places : [],
            setViewport: function (v) {
                setViewport(v);
            },
            searchPlaces: searchPlaces,
        } }, { children: children }), void 0));
};
export default useMap;
