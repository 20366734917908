var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { tw } from 'twind';
import { useEffect, useState } from 'react';
import useModal from '../../hooks/useModal';
import { useParse } from '../../hooks/useParse';
import TextField, { TextFieldLabel } from '../TextField';
import { ModalFooter, ModalHeader } from './BaseModal';
import { FaSpinner } from 'react-icons/fa';
import Button from '../Button';
export var LoginModal = function () {
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState(), error = _b[0], setError = _b[1];
    var _c = useState(), parseError = _c[0], setParseError = _c[1];
    var _d = useState({
        username: '',
        password: '',
    }), lastAttempt = _d[0], setLastAttempt = _d[1];
    var _e = useState(''), username = _e[0], setUsername = _e[1];
    var _f = useState(''), password = _f[0], setPassword = _f[1];
    var _g = useState(true), disableSubmit = _g[0], setDisableSubmit = _g[1];
    var _h = useParse(), login = _h.login, authenticated = _h.authenticated;
    var modal = useModal();
    useEffect(function () {
        if (authenticated) {
            modal.hide();
        }
    }, []);
    useEffect(function () {
        var disable = false;
        if (username.trim().length < 1) {
            setError('Username cannot be empty');
            disable = true;
        }
        else if (password.trim().length < 1) {
            setError('Password cannot be empty');
            disable = true;
        }
        else if (username === lastAttempt.username &&
            password === lastAttempt.password) {
            disable = true;
        }
        setDisableSubmit(disable);
    }, [username, password, lastAttempt]);
    return (_jsxs("form", __assign({ onSubmit: function (e) { return __awaiter(void 0, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        e.preventDefault();
                        setLoading(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, login(username, password)];
                    case 2:
                        _a.sent();
                        modal.show({ name: 'settings', props: {} });
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        setLastAttempt({ username: username, password: password });
                        setParseError(e_1.message || e_1);
                        return [3 /*break*/, 4];
                    case 4:
                        setLoading(false);
                        return [2 /*return*/];
                }
            });
        }); } }, { children: [_jsx(ModalHeader, { title: 'Administrative Login', subtitle: disableSubmit && (parseError || error) ? parseError || error : '', subtitleStyle: "danger" }, void 0), _jsxs("div", __assign({ className: tw("flex flex-col space-y-4 pt-4") }, { children: [_jsxs("div", __assign({ className: tw("flex flex-col space-y-2") }, { children: [_jsx(TextFieldLabel, __assign({ htmlFor: "username", danger: Boolean(parseError || error) && disableSubmit }, { children: "Username" }), void 0), _jsx(TextField, { id: "username", type: "text", value: username, error: Boolean(parseError || error) && disableSubmit, onChange: function (_a) {
                                    var target = _a.target;
                                    return setUsername(target.value);
                                }, placeholder: 'olive-yew' }, void 0)] }), void 0), _jsxs("div", __assign({ className: tw("flex flex-col space-y-2") }, { children: [_jsx(TextFieldLabel, __assign({ htmlFor: "password", danger: Boolean(parseError || error) && disableSubmit }, { children: "Password" }), void 0), _jsx(TextField, { id: "username", type: "password", value: password, error: Boolean(parseError || error) && disableSubmit, onChange: function (_a) {
                                    var target = _a.target;
                                    return setPassword(target.value);
                                }, placeholder: '••••••••••••••' }, void 0)] }), void 0)] }), void 0), _jsxs(ModalFooter, { children: [_jsx(Button, __assign({ disabled: disableSubmit || loading, type: "submit", primary: true }, { children: loading ? (_jsxs("div", __assign({ className: tw("flex justify-center items-center space-x-2") }, { children: [_jsx(FaSpinner, { className: tw("inline animate-spin") }, void 0), _jsx("span", { children: "Submitting" }, void 0)] }), void 0)) : (_jsx("span", { children: "Submit" }, void 0)) }), void 0), _jsx(Button, __assign({ type: "button", onClick: function () { return modal.hide(); } }, { children: "Close" }), void 0), _jsx(Button, __assign({ type: "button", onClick: function () { return modal.show({ name: 'settings', props: {} }); } }, { children: "Back to Settings" }), void 0)] }, void 0)] }), void 0));
};
export default LoginModal;
