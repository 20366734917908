export var isValidNotificationText = function (notification) {
    var title = notification.title, subtitle = notification.subtitle, message = notification.message;
    return Boolean((title && title.trim().length > 0) ||
        (subtitle && subtitle.trim().length > 0) ||
        (message && message.trim().length > 0));
};
export var isValidNotification = function (notification) {
    var playerIds = notification.playerIds, segments = notification.segments;
    if ((!playerIds && !segments) ||
        (playerIds && playerIds.length < 1) ||
        (segments && segments.length < 1)) {
        return false;
    }
    else if (!isValidNotificationText(notification)) {
        return false;
    }
    return true;
};
