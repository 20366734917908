export var Theme = {
    System: { value: 'system', icon: 'contrast' },
    Light: { value: 'light', icon: 'sun' },
    Dark: { value: 'dark', icon: 'moon' },
};
export var LightTheme = {
    mapbox: 'mapbox://styles/ifa-jacob-shuman/cksqjivib16zc17pi0zqdbxgg',
    page: '#FFFFFF',
    text: '#303030',
    placeholder: '#C4C4C4',
    secondaryText: '#959595',
    border: '#F2F2F2',
    divider: '#E5E5E5',
    primary: '#1B76FF',
    primaryText: '#E4EFFF',
    secondary: '#E4EFFF',
    danger: '#DC2626',
    dangerText: '#FFCBCB',
    dangerPlaceholder: '#F29191',
    disabled: {
        text: '#A0A0A0',
        bg: '#F2F2F2',
    },
};
export var DarkTheme = {
    mapbox: 'mapbox://styles/ifa-jacob-shuman/ckvhjjf7r42r014ntvo4ycv64',
    page: '#101010',
    text: '#C0C0C0',
    placeholder: '#C4C4C4',
    secondaryText: '#656565',
    border: '#353535',
    divider: '#2F2F2F',
    primary: '#1B76FF',
    primaryText: '#E4EFFF',
    secondary: '#193053',
    danger: '#DC2626',
    dangerText: '#FFCBCB',
    dangerPlaceholder: '#F29191',
    disabled: {
        text: '#808080',
        bg: '#353535',
    },
};
