var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { tw } from 'twind';
import { Colors, Styles } from '../constants/constants-styles';
import useModal from '../hooks/useModal';
import useTheme from '../hooks/useTheme';
import Card from './Card';
import Icon from './Icon';
var PlaceCard = function (_a) {
    var children = _a.children, active = _a.active, className = _a.className, place = _a.place, props = __rest(_a, ["children", "active", "className", "place"]);
    var modal = useModal();
    var theme = useTheme();
    return (_jsxs("div", __assign({ className: tw("flex space-x-2") }, { children: [_jsxs(Card, __assign({ className: tw(Styles.transition, Styles.hover, Styles.focus, "w-full h-24 flex flex-col justify-center", "text-[" + theme.text + "] text-left", "border-4 border-[" + theme.border + "]", active && tw("bg-[" + theme.secondary + "] border-opacity-0"), className) }, props, { children: [_jsx("h1", __assign({ className: tw(Styles.font.body, "font-bold text-lg", active && "text-[" + Colors.primary + "]") }, { children: place.attributes.name }), void 0), _jsx("p", __assign({ className: tw(Styles.font.body, "font-semibold", active && "text-[" + Colors.primary + "]") }, { children: place.attributes.address.streetAddress }), void 0)] }), void 0), _jsx(Card, __assign({ className: tw("text-[" + theme.text + "] focus:outline-none hover:shadow active:shadow-inner"), onClick: function () { return modal.show({ name: 'place', props: { place: place } }); } }, { children: _jsx(Icon, { as: "profile" }, void 0) }), void 0)] }), void 0));
};
export default PlaceCard;
