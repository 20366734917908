var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { tw } from 'twind';
import { ModalHeader, ModalFooter } from './BaseModal';
import useModal from '../../hooks/useModal';
import { Colors, Styles } from '../../constants/constants-styles';
import { useState } from 'react';
import Icon from '../Icon';
import { IoTrashBinSharp } from 'react-icons/io5';
import TextField from '../TextField';
import Button from '../Button';
export var ChangeAnnouncementModal = function (_a) {
    var _b, _c;
    var title = _a.title, description = _a.description, links = _a.links, announcement = _a.announcement;
    var modal = useModal();
    var _d = useState(announcement === null || announcement === void 0 ? void 0 : announcement.attributes.title), newTitle = _d[0], setNewTitle = _d[1];
    var _e = useState(announcement === null || announcement === void 0 ? void 0 : announcement.attributes.description), newDescription = _e[0], setNewDescription = _e[1];
    var _f = useState(announcement === null || announcement === void 0 ? void 0 : announcement.attributes.subtitle), newSubtitle = _f[0], setNewSubtitle = _f[1];
    var newLink = '';
    var _g = useState((_b = announcement === null || announcement === void 0 ? void 0 : announcement.attributes.links) !== null && _b !== void 0 ? _b : []), newLinks = _g[0], setNewLinks = _g[1];
    var handleLinkChange = function (e, index) {
        var updatedLinks = __spreadArray([], newLinks);
        //@ts-ignore
        updatedLinks[index] = e.target.value;
        setNewLinks(updatedLinks);
    };
    var handleSubmit = function () {
        console.log({
            title: newTitle,
            description: newDescription,
            links: newLinks,
        });
    };
    return (_jsx(_Fragment, { children: _jsxs("form", __assign({ onSubmit: function (e) { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    e.preventDefault();
                    try {
                        handleSubmit();
                    }
                    catch (error) {
                        console.log('an error has occurred.');
                    }
                    return [2 /*return*/];
                });
            }); } }, { children: [_jsx(ModalHeader, { title: (_c = announcement === null || announcement === void 0 ? void 0 : announcement.attributes.title) !== null && _c !== void 0 ? _c : 'New Announcement', actions: announcement && [
                        {
                            icon: IoTrashBinSharp,
                            className: tw(templateObject_1 || (templateObject_1 = __makeTemplateObject(["text-red-600"], ["text-red-600"]))),
                            onClick: function () { return console.log(announcement === null || announcement === void 0 ? void 0 : announcement.id); },
                        },
                    ] }, void 0), _jsxs("div", __assign({ className: tw(templateObject_2 || (templateObject_2 = __makeTemplateObject(["flex flex-row justify-between"], ["flex flex-row justify-between"]))) }, { children: [_jsxs("div", __assign({ className: tw(templateObject_3 || (templateObject_3 = __makeTemplateObject(["flex flex-col w-full"], ["flex flex-col w-full"]))) }, { children: [_jsx("p", __assign({ className: tw(templateObject_4 || (templateObject_4 = __makeTemplateObject(["font-bold"], ["font-bold"]))) }, { children: "Title" }), void 0), _jsx(TextField, { value: newTitle, onChange: function (e) { return setNewTitle(e.target.value); }, className: tw("w-11/12 resize-none rounded-lg text-lg focus:outline-none bg-[" + Colors.light + "]"), error: false }, void 0), _jsx("p", __assign({ className: tw(templateObject_5 || (templateObject_5 = __makeTemplateObject(["font-bold"], ["font-bold"]))) }, { children: "Sub-Title" }), void 0), _jsx(TextField, { value: newSubtitle, onChange: function (e) { return setNewSubtitle(e.target.value); }, className: tw("w-11/12 resize-none rounded-lg text-lg focus:outline-none bg-[" + Colors.light + "]"), error: false }, void 0), _jsx("p", __assign({ className: tw(templateObject_6 || (templateObject_6 = __makeTemplateObject(["font-bold"], ["font-bold"]))) }, { children: "Links" }), void 0), newLinks === null || newLinks === void 0 ? void 0 : newLinks.map(function (link, i) {
                                    return (_jsx(TextField, { error: false, defaultValue: link, className: tw("w-11/12 resize-none rounded-lg text-lg focus:outline-none bg-[" + Colors.light + "] mb-2"), onChange: function (e) { return handleLinkChange(e, i); } }, i));
                                }), _jsx(Icon, { onClick: function () { return setNewLinks(__spreadArray(__spreadArray([], newLinks), [newLink])); }, as: 'plus', small: true, className: tw(templateObject_7 || (templateObject_7 = __makeTemplateObject(["self-end mr-8"], ["self-end mr-8"]))) }, void 0)] }), void 0), _jsxs("div", __assign({ className: tw(templateObject_8 || (templateObject_8 = __makeTemplateObject(["w-full"], ["w-full"]))) }, { children: [_jsx("p", __assign({ className: tw(templateObject_9 || (templateObject_9 = __makeTemplateObject(["font-bold"], ["font-bold"]))) }, { children: "Description" }), void 0), _jsx("textarea", { value: newDescription, onChange: function (e) { return setNewDescription(e.target.value); }, className: tw(Styles.rounded, Styles.font.body, Styles.transition, "border-black border-opacity-5 bg-transparent", "border-4 px-4 py-2", "focus-within:(border-[" + Colors.primary + "]) focus:outline-none", "text-lg text-left cursor-text font-bold text-[" + Colors.dark + "] placeholder-[" + Colors.medium + "] h-full w-full resize-none") }, void 0)] }), void 0)] }), void 0), _jsxs(ModalFooter, { children: [_jsx(Button, __assign({ type: "submit", className: tw(templateObject_10 || (templateObject_10 = __makeTemplateObject(["text-white! bg-[", "]!"], ["text-white! bg-[", "]!"])), Colors.primary) }, { children: "Save" }), void 0), _jsx(Button, __assign({ onClick: function () {
                                announcement
                                    ? modal.show({
                                        name: 'announcement',
                                        props: { announcement: announcement },
                                    })
                                    : modal.hide();
                                !announcement && modal.show({ name: 'settings', props: {} });
                            } }, { children: "Cancel" }), void 0)] }, void 0)] }), void 0) }, void 0));
};
export default ChangeAnnouncementModal;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
