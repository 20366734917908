var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { tw } from 'twind';
import { ModalHeader, ModalFooter } from './BaseModal';
import useModal from '../../hooks/useModal';
import useTheme from '../../hooks/useTheme';
import { useNotifications } from '../../hooks/notifications/useNotifications';
import Button from '../Button';
var NotificationListTile = function (_a) {
    var notification = _a.notification, className = _a.className, props = __rest(_a, ["notification", "className"]);
    var theme = useTheme();
    return (_jsx("button", __assign({ className: tw("w-full text-left focus:outline-none", "flex space-x-4 items-center", className) }, props, { children: _jsxs("div", __assign({ className: tw("text-[" + theme.text + "]") }, { children: [notification.title && (_jsx("h2", __assign({ className: tw("text-2xl") }, { children: notification.title }), void 0)), notification.subtitle && _jsx("h3", { children: notification.subtitle }, void 0)] }), void 0) }), void 0));
};
export var ViewNotificationsModal = function (_a) {
    var modal = useModal();
    var theme = useTheme();
    var notifications = useNotifications();
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { title: !notifications.all || notifications.all.length < 1
                    ? 'No Notifications'
                    : 'View Notifications' }, void 0), _jsx("section", __assign({ className: tw("flex flex-col space-y-4") }, { children: notifications &&
                    notifications.all.length > 0 &&
                    notifications.all.map(function (n, i) {
                        var _a;
                        return (_jsx(NotificationListTile, { notification: n }, i + ((_a = n.attributes.title) !== null && _a !== void 0 ? _a : '') + new Date().getUTCMilliseconds()));
                    }) }), void 0), _jsxs(ModalFooter, { children: [_jsx(Button, __assign({ primary: true, type: "button", onClick: function () { return modal.show({ name: 'settings', props: {} }); } }, { children: "Back to Settings" }), void 0), _jsx(Button, __assign({ type: "button", onClick: function () { return modal.hide(); } }, { children: "Close" }), void 0)] }, void 0)] }, void 0));
};
export default ViewNotificationsModal;
