var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import '@fontsource/poppins/100.css';
import '@fontsource/poppins/200.css';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/800.css';
import '@fontsource/poppins/900.css';
import '@fontsource/raleway/100.css';
import '@fontsource/raleway/200.css';
import '@fontsource/raleway/300.css';
import '@fontsource/raleway/400.css';
import '@fontsource/raleway/500.css';
import '@fontsource/raleway/600.css';
import '@fontsource/raleway/700.css';
import '@fontsource/raleway/800.css';
import '@fontsource/raleway/900.css';
import '@fontsource/inter/100.css';
import '@fontsource/inter/200.css';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/800.css';
import '@fontsource/inter/900.css';
import { tw } from 'twind';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import useSettings, { SettingsProvider } from './hooks/useSettings';
import { ThemeProvider } from './hooks/useTheme';
import { ParseProvider } from './hooks/useParse';
import { DownloadsProvider } from './hooks/useDownloads';
import { MapProvider } from './hooks/useMap';
import { ModalProvider } from './hooks/useModal';
import { AnnouncementsProvider } from './hooks/useAnnouncements';
import { NetworkProvider } from './hooks/useNetwork';
import DownloadsPage from './pages/Downloads';
import HomePage from './pages/Home';
import NearMePage from './pages/NearMe';
import { useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { OneSignalProvider, useOneSignal, } from './hooks/notifications/useOneSignal';
import { NotificationsProvider, useNotifications, } from './hooks/notifications/useNotifications';
import AboutPage from './pages/About';
var Providers = function (_a) {
    var children = _a.children;
    return (_jsx(NetworkProvider, { children: _jsx(SettingsProvider, __assign({ lsKey: "indigihealth-settings" }, { children: _jsx(ThemeProvider, { children: _jsx(ParseProvider, { children: _jsx(MapProvider, { children: _jsx(NotificationsProvider, { children: _jsx(DownloadsProvider, { children: _jsx(ModalProvider, { children: _jsx(AnnouncementsProvider, { children: _jsx(OneSignalProvider, { children: children }, void 0) }, void 0) }, void 0) }, void 0) }, void 0) }, void 0) }, void 0) }, void 0) }), void 0) }, void 0));
};
var NotificationWrapper = function (_a) {
    var children = _a.children;
    var settings = useSettings();
    var notifications = useNotifications();
    var onesignal = useOneSignal();
    useEffect(function () {
        // TODO: remove getPlatform condition once web push notifications are implemented
        if (settings.initialized && Capacitor.getPlatform() !== 'web') {
            if (!notifications.initialized) {
                notifications.initialize();
            }
            else if (notifications.initialized && !onesignal.initialized) {
                onesignal
                    .initialize()
                    .then(function (osPlayerId) {
                    var _a, _b;
                    settings.update(process.env.NODE_ENV === 'production'
                        ? {
                            osPlayerId: __assign(__assign({}, ((_a = settings.osPlayerId) !== null && _a !== void 0 ? _a : {})), { prod: osPlayerId }),
                        }
                        : {
                            osPlayerId: __assign(__assign({}, ((_b = settings.osPlayerId) !== null && _b !== void 0 ? _b : {})), { dev: osPlayerId }),
                        });
                })
                    .catch(console.error);
            }
        }
    }, [settings, notifications, onesignal]);
    return _jsx(_Fragment, { children: children }, void 0);
};
var App = function () { return (_jsxs(_Fragment, { children: [_jsx(Toaster, { containerClassName: tw(templateObject_1 || (templateObject_1 = __makeTemplateObject(["safe-mt"], ["safe-mt"]))), toastOptions: { duration: 4000 } }, void 0), _jsx(Providers, { children: _jsx(NotificationWrapper, { children: _jsx(BrowserRouter, { children: _jsxs(Switch, { children: [_jsx(Route, __assign({ path: "/home" }, { children: _jsx(HomePage, {}, void 0) }), void 0), _jsx(Route, __assign({ path: "/about" }, { children: _jsx(AboutPage, {}, void 0) }), void 0), _jsx(Route, __assign({ path: "/nearme" }, { children: _jsx(NearMePage, {}, void 0) }), void 0), _jsx(Route, __assign({ path: "/downloads" }, { children: _jsx(DownloadsPage, {}, void 0) }), void 0), _jsx(Route, __assign({ path: "*" }, { children: _jsx(Redirect, { from: "/", to: "home" }, void 0) }), void 0)] }, void 0) }, void 0) }, void 0) }, void 0)] }, void 0)); };
export default App;
var templateObject_1;
