var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { tw, css } from 'twind/css';
import { Colors, Styles } from '../constants/constants-styles';
import useModal from '../hooks/useModal';
import Card from './Card';
import { useDownloads } from '../hooks/useDownloads';
import Icon from './Icon';
import { useMemo } from 'react';
import { useNetwork } from '../hooks/useNetwork';
var PostCard = function (_a) {
    var className = _a.className, wide = _a.wide, _post = _a.post, onClick = _a.onClick, props = __rest(_a, ["className", "wide", "post", "onClick"]);
    var network = useNetwork();
    var _b = useDownloads(), download = _b.download, isDownloaded = _b.isDownloaded;
    var modal = useModal();
    var _c = useMemo(function () { var _a; return [_post, (_a = _post.attributes.image) === null || _a === void 0 ? void 0 : _a.url()]; }, [_post]), post = _c[0], image = _c[1];
    return (_jsx("div", __assign({ className: tw(Styles.rounded, Styles.transition, image && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n            background-image: url('", "');\n            background-size: cover;\n            background-postion: center;\n          "], ["\n            background-image: url('", "');\n            background-size: cover;\n            background-postion: center;\n          "])), image)) }, { children: _jsx("div", __assign({ className: tw(Styles.rounded, Styles.transition, css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n            background: linear-gradient(\n              180deg,\n              rgba(0, 0, 0, 0) 0%,\n              rgba(0, 0, 0, 0.5) 100%\n            );\n          "], ["\n            background: linear-gradient(\n              180deg,\n              rgba(0, 0, 0, 0) 0%,\n              rgba(0, 0, 0, 0.5) 100%\n            );\n          "]))), "hover:(bg-black bg-opacity-25) active:(bg-black bg-opacity-50)") }, { children: _jsxs(Card, __assign({ className: tw(Styles.transition, Styles.border.base, "flex items-end justify-between", "w-full h-32 md:h-80", "border-opacity-5 border-black", "focus:(border-opacity-100 border-[" + Colors.primary + "] outline-none)", className), onClick: onClick !== null && onClick !== void 0 ? onClick : (function () {
                    return modal.show({
                        name: 'post',
                        props: { post: post },
                    });
                }) }, props, { children: [_jsxs("h3", __assign({ className: tw(Styles.font.title, "text-xl lg:text-2xl text-white text-left font-bold max-w-[75%] break-words") }, { children: [post.attributes.title.substring(0, 40).trim(), post.attributes.title.length > 40 && '...'] }), void 0), network.connected && (_jsx(Icon, { small: true, as: isDownloaded(post.id) ? 'downloaded' : 'download', className: tw(Styles.transition, "min-w-max text-white"), onClick: function (e) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        e.stopPropagation();
                                        return [4 /*yield*/, download(post)];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); } }, void 0))] }), void 0) }), void 0) }), void 0));
};
export default PostCard;
var templateObject_1, templateObject_2;
