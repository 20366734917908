var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { tw, css } from 'twind/css';
import Card from './Card';
import { Colors, Styles } from '../constants/constants-styles';
import useModal from '../hooks/useModal';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useEffect, useState } from 'react';
export var AnnouncementCard = function (_a) {
    var _b, _c, _d;
    var announcement = _a.announcement, onClick = _a.onClick, props = __rest(_a, ["announcement", "onClick"]);
    var _e = useState((_c = (_b = announcement.attributes.image) === null || _b === void 0 ? void 0 : _b.url()) !== null && _c !== void 0 ? _c : ''), image = _e[0], setImage = _e[1];
    var modal = useModal();
    useEffect(function () {
        var _a, _b;
        setImage((_b = (_a = announcement.attributes.image) === null || _a === void 0 ? void 0 : _a.url()) !== null && _b !== void 0 ? _b : '');
    }, [announcement]);
    return (_jsx("div", __assign({ className: tw(Styles.rounded, "w-full", css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n          background-image: url('", "');\n          background-size: cover;\n          background-postion: center;\n        "], ["\n          background-image: url('", "');\n          background-size: cover;\n          background-postion: center;\n        "])), image)) }, { children: _jsx("div", __assign({ className: tw(Styles.rounded, Styles.transition, css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n            background: linear-gradient(\n              180deg,\n              rgba(0, 0, 0, 0.3) 0%,\n              rgba(0, 0, 0, 0.5) 100%\n            );\n          "], ["\n            background: linear-gradient(\n              180deg,\n              rgba(0, 0, 0, 0.3) 0%,\n              rgba(0, 0, 0, 0.5) 100%\n            );\n          "]))), "hover:(bg-black bg-opacity-25) active:(bg-black bg-opacity-50)") }, { children: _jsxs(Card, __assign({ className: tw(Styles.transition, Styles.border.base, "flex flex-col w-full justify-between text-left lg:h-96 xl:h-80 2xl:h-72", "border-black border-opacity-5", "focus:(border-opacity-100 border-[" + Colors.primary + "] outline-none)"), onClick: function (e) {
                    onClick === null || onClick === void 0 ? void 0 : onClick(e);
                    modal.show({
                        name: 'announcement',
                        props: { announcement: announcement },
                    });
                } }, props, { children: [_jsxs("div", __assign({ className: tw("flex flex-col") }, { children: [_jsx("h1", __assign({ className: tw(Styles.font.title, Styles.transition, "text-white text-base font-bold lg:text-3xl") }, { children: announcement.attributes.title }), void 0), _jsx("h2", __assign({ className: tw(Styles.font.body, Styles.transition, "text-white text-sm font-semibold lg:text-xl") }, { children: (_d = announcement.attributes.subtitle) !== null && _d !== void 0 ? _d : 'asdf' }), void 0)] }), void 0), _jsx("h3", __assign({ className: tw(Styles.font.body, Styles.transition, "text-white text-sm font-semibold lg:text-base") }, { children: (function () {
                            dayjs.extend(relativeTime);
                            return dayjs(announcement.createdAt).from(new Date()) + " (updated " + dayjs(announcement.updatedAt).from(new Date()) + ")";
                        })() }), void 0)] }), void 0) }), void 0) }), void 0));
};
export default AnnouncementCard;
var templateObject_1, templateObject_2;
