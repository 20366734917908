var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import Parse from 'parse';
import { useParse } from './useParse';
var AnnouncementsContext = React.createContext({
    announcements: [],
});
export var useAnnouncements = function () { return React.useContext(AnnouncementsContext); };
export var AnnouncementsProvider = function (_a) {
    var children = _a.children;
    var _b = useParse(), initialized = _b.initialized, connected = _b.connected;
    var _c = useState([]), announcements = _c[0], setAnnouncements = _c[1];
    useEffect(function () {
        if (initialized && connected) {
            var query = Parse.Query.or(new Parse.Query('Announcement')
                .equalTo('enabled', true)
                .greaterThan('expireDate', new Date()), new Parse.Query('Announcement')
                .equalTo('enabled', true)
                .equalTo('expireDate', undefined));
            query.find().then(function (results) { return setAnnouncements(results); });
        }
    }, [initialized, connected]);
    return (_jsx(AnnouncementsContext.Provider, __assign({ value: { announcements: announcements } }, { children: children }), void 0));
};
export default useAnnouncements;
