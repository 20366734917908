var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { tw } from 'twind';
import { Styles } from '../constants/constants-styles';
import useTheme from '../hooks/useTheme';
import TopicCard from './TopicCard';
var TopicSection = function (_a) {
    var topics = _a.topics, setTerms = _a.setTerms;
    var _b = useState([]), activeTopics = _b[0], setActiveTopics = _b[1];
    var theme = useTheme();
    useEffect(function () {
        if (setTerms) {
            setTerms(topics
                .filter(function (_a) {
                var id = _a.id;
                return activeTopics.includes(id);
            })
                .map(function (t) { return t.attributes.title; }));
        }
    }, [topics, activeTopics, setTerms]);
    return (_jsxs(_Fragment, { children: [_jsx("h3", __assign({ className: tw(Styles.font.title, "hidden lg:block font-bold text-2xl mb-4 text-[" + theme.text + "]") }, { children: "Topics" }), void 0), _jsx("section", __assign({ className: tw("flex space-x-4 py-4", "lg:(flex-col space-x-0 space-y-4)") }, { children: topics.map(function (topic, index) { return (_jsx(TopicCard, __assign({ active: activeTopics.includes(topic.id), onClick: function () {
                        if (activeTopics.includes(topic.id)) {
                            setActiveTopics(activeTopics.filter(function (t) { return t !== topic.id; }));
                        }
                        else {
                            setActiveTopics(__spreadArray(__spreadArray([], activeTopics), [topic.id]));
                        }
                        if (setTerms) {
                            setTerms(topics
                                .filter(function (_a) {
                                var id = _a.id;
                                return activeTopics.includes(id);
                            })
                                .map(function (t) { return t.attributes.title; }));
                        }
                    } }, { children: topic.attributes.title }), index)); }) }), void 0)] }, void 0));
};
export default TopicSection;
