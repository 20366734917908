var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { tw } from 'twind';
import { Colors, Styles } from '../constants/constants-styles';
import useTheme from '../hooks/useTheme';
import Card from './Card';
import Icon from './Icon';
export var TopicCard = function (_a) {
    var children = _a.children, active = _a.active, props = __rest(_a, ["children", "active"]);
    var theme = useTheme();
    return (_jsx(Card, __assign({ className: tw(Styles.transition, Styles.hover, Styles.focus, Styles.border.block, "min-w-max lg:w-40 lg:w-full border-[" + theme.border + "]", active && tw("bg-[" + theme.secondary + "] border-opacity-0")) }, props, { children: _jsxs("div", __assign({ className: tw("flex items-center space-x-2 h-4 md:h-6") }, { children: [active && (_jsx(Icon, { as: 'checkmark', className: tw("text-[" + Colors.primary + "]", "w-6 h-6", "lg:(w-8 h-8)", "2xl:(w-11 h-11)") }, void 0)), _jsx("div", __assign({ className: tw(templateObject_1 || (templateObject_1 = __makeTemplateObject(["flex-grow"], ["flex-grow"]))) }, { children: _jsx("p", __assign({ className: tw(Styles.font.body, "text-center font-bold text-sm md:text-lg lg:text-2xl min-w-max", active ? "text-[" + Colors.primary + "]" : "text-[" + theme.text + "]") }, { children: children }), void 0) }), void 0)] }), void 0) }), void 0));
};
export default TopicCard;
var templateObject_1;
