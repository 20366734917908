var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import mapboxgl from 'mapbox-gl'; // This is a dependency of react-map-gl even if you didn't explicitly install it
import { useEffect, useState } from 'react';
import Map, { Marker } from 'react-map-gl';
import { tw } from 'twind';
import Icon from '../components/Icon';
import Nav, { NavContainer } from '../components/Nav';
import Page from '../components/Page';
import PlaceCard from '../components/PlaceCard';
import { ScrollTopButton } from '../components/ScrollTopButton';
import Search from '../components/Search';
import { Colors, Styles } from '../constants/constants-styles';
import useMap, { DefaultViewport } from '../hooks/useMap';
import useModal from '../hooks/useModal';
import useTheme from '../hooks/useTheme';
// @ts-ignore
mapboxgl.workerClass =
    // eslint-disable-next-line import/no-webpack-loader-syntax
    require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
var NearMePage = function () {
    var _a = useMap(), places = _a.places, viewport = _a.viewport, setViewport = _a.setViewport, searchPlaces = _a.searchPlaces;
    var _b = useState(), selectedPlace = _b[0], setSelectedPlace = _b[1];
    var _c = useState(''), searchQuery = _c[0], setSearchQuery = _c[1];
    var _d = useState([]), filteredPlaces = _d[0], setFilteredPlaces = _d[1];
    var modal = useModal();
    var theme = useTheme();
    useEffect(function () {
        if (selectedPlace) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
            var place = places.filter(function (_a) {
                var id = _a.id;
                return selectedPlace === id;
            })[0];
            if (place) {
                setViewport(__assign(__assign({}, DefaultViewport), { latitude: place.attributes.geoPoint.latitude, longitude: place.attributes.geoPoint.longitude, zoom: 15 }));
            }
        }
    }, [selectedPlace]);
    useEffect(function () {
        var results = searchPlaces.search(searchQuery).map(function (_a) {
            var item = _a.item;
            return item;
        });
        setFilteredPlaces(results);
    }, [searchQuery]);
    return (_jsxs(Page, __assign({ nav: false, className: tw(templateObject_1 || (templateObject_1 = __makeTemplateObject(["flex flex-col-reverse justify-between hide-scroll lg:flex-row"], ["flex flex-col-reverse justify-between hide-scroll lg:flex-row"]))) }, { children: [_jsx(NavContainer, { children: _jsx(Nav, {}, void 0) }, void 0), _jsxs("section", __assign({ className: tw(templateObject_2 || (templateObject_2 = __makeTemplateObject(["pb-20 mt-5 lg:w-4/12"], ["pb-20 mt-5 lg:w-4/12"]))), style: { height: '75vh' } }, { children: [_jsx(Search, { query: searchQuery, setQuery: setSearchQuery }, void 0), _jsx("h1", __assign({ className: tw(Styles.font.title, "text-[" + theme.text + "] font-bold text-sm px-4 py-2") }, { children: "Search powered by Clinia" }), void 0), _jsx("div", __assign({ className: tw("flex flex-col space-y-4 h-0.5") }, { children: (searchQuery && searchQuery.length > 0
                            ? filteredPlaces
                            : places).map(function (place) { return (_jsx(PlaceCard, { place: place, active: place.id === selectedPlace, onClick: function () {
                                if (selectedPlace !== place.id) {
                                    setSelectedPlace(place.id);
                                }
                                else {
                                    modal.show({ name: 'place', props: { place: place } });
                                }
                            } }, void 0)); }) }), void 0)] }), void 0), _jsx("section", __assign({ className: tw(templateObject_3 || (templateObject_3 = __makeTemplateObject(["flex justify-end w-full mt-24 lg:(mt-5 w-7/12)"], ["flex justify-end w-full mt-24 lg:(mt-5 w-7/12)"]))) }, { children: _jsx(Map, __assign({}, viewport, { mapboxApiAccessToken: process.env.REACT_APP_MAPBOX_TOKEN, mapStyle: "" + theme.mapbox + (process.env.NODE_ENV !== 'production' ? '/draft' : ''), onViewportChange: function (v) { return setViewport(v); }, className: tw("-z-10", Styles.rounded) }, { children: places.map(function (place) {
                        var selected = selectedPlace === place.id;
                        var props = {
                            as: selected ? 'pin-active' : 'pin',
                            className: tw(Styles.transition + " " + Styles.hover, selected
                                ? tw(templateObject_4 || (templateObject_4 = __makeTemplateObject(["w-16 h-16 text-[", "]"], ["w-16 h-16 text-[", "]"])), Colors.primary) : tw(templateObject_5 || (templateObject_5 = __makeTemplateObject(["w-10 h-10"], ["w-10 h-10"])))),
                        };
                        return (_jsx(Marker, __assign({ latitude: place.attributes.geoPoint.latitude, longitude: place.attributes.geoPoint.longitude, offsetTop: -20 - viewport.zoom, offsetLeft: -16 - viewport.zoom, captureClick: true }, { children: _jsx("button", __assign({ className: tw("text-[" + theme.text + "] focus:outline-none"), onClick: function () {
                                    if (selectedPlace !== place.id) {
                                        setSelectedPlace(place.id);
                                    }
                                    else {
                                        modal.show({ name: 'place', props: { place: place } });
                                    }
                                } }, { children: _jsx(Icon, __assign({}, props), void 0) }), void 0) }), place.id));
                    }) }), void 0) }), void 0), _jsx(ScrollTopButton, { className: tw(templateObject_6 || (templateObject_6 = __makeTemplateObject(["fixed right-0 bottom-28 lg:bottom-5 right-5"], ["fixed right-0 bottom-28 lg:bottom-5 right-5"]))) }, void 0)] }), void 0));
};
export default NearMePage;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
