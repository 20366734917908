var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { StorageKeys } from '../constants/constants-general';
import * as Parse from 'parse';
import { useParse } from './useParse';
import { useNotifications } from './notifications/useNotifications';
var DownloadsContext = React.createContext({
    downloads: [],
    downloadedIds: [],
    download: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/];
    }); }); },
    isDownloaded: function () { return false; },
    clearDownloads: function () { },
});
export var DownloadsProvider = function (_a) {
    var children = _a.children;
    var initialized = useParse().initialized;
    var notifications = useNotifications();
    var _b = useState({}), _allDownloads = _b[0], setAllDownloads = _b[1];
    var _c = useState([]), downloads = _c[0], setDownloads = _c[1];
    var _d = useState([]), downloadedIds = _d[0], setDownloadedIds = _d[1];
    var _buildDownloadsObject = function (downloads) { return downloads.reduce(function (prev, curr) {
        var _a;
        return (__assign(__assign({}, prev), (_a = {}, _a[curr.id] = curr, _a)));
    }, {}); };
    var download = useCallback(function (post) { return __awaiter(void 0, void 0, void 0, function () {
        var downloaded;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    downloaded = downloadedIds.includes(post.id);
                    if (!downloaded) {
                        post.pinWithName(StorageKeys.downloads);
                        setAllDownloads(__assign(__assign({}, _allDownloads), (_a = {}, _a[post.id] = post, _a)));
                    }
                    else {
                        post.unPinWithName(StorageKeys.downloads);
                        setAllDownloads(_buildDownloadsObject(Object.entries(_allDownloads)
                            .filter(function (_a) {
                            var k = _a[0];
                            return k !== post.id;
                        })
                            .map(function (_a) {
                            var _ = _a[0], v = _a[1];
                            return v;
                        })));
                    }
                    if (!notifications.playerId) return [3 /*break*/, 2];
                    return [4 /*yield*/, Parse.Cloud.run('downloadPost', {
                            postId: post.id,
                            playerId: notifications.playerId,
                            downloaded: downloaded,
                        })];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); }, [_allDownloads, downloadedIds, notifications.playerId]);
    var isDownloaded = useCallback(function (id) { return downloadedIds.includes(id); }, [downloadedIds]);
    var clearDownloads = function () {
        if (initialized) {
            Parse.Object.unPinAllObjectsWithName(StorageKeys.downloads);
            setAllDownloads({});
        }
    };
    var retrieveOfflineDownloads = function () { return __awaiter(void 0, void 0, void 0, function () {
        var rawDownloads, downloads;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, new Parse.Query('Post')
                        .fromLocalDatastore()
                        .fromPinWithName(StorageKeys.downloads)
                        .find()];
                case 1:
                    rawDownloads = _a.sent();
                    downloads = rawDownloads.reduce(function (prev, curr, i) {
                        var _a;
                        return (__assign(__assign({}, prev), (_a = {}, _a[curr.id] = curr, _a)));
                    }, {});
                    setAllDownloads(downloads);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (initialized) {
            retrieveOfflineDownloads();
        }
    }, [initialized]);
    useEffect(function () {
        setDownloads(Object.values(_allDownloads));
        setDownloadedIds(Object.values(_allDownloads).map(function (p) { return p.id; }));
    }, [_allDownloads]);
    return (_jsx(DownloadsContext.Provider, __assign({ value: {
            downloads: downloads,
            downloadedIds: downloadedIds,
            download: download,
            isDownloaded: isDownloaded,
            clearDownloads: clearDownloads,
        } }, { children: children }), void 0));
};
export var useDownloads = function () { return React.useContext(DownloadsContext); };
