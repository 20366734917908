export var parseOSNotification = function (osNotification) {
    var _a, _b, _c;
    return ({
        title: (_a = osNotification === null || osNotification === void 0 ? void 0 : osNotification.payload) === null || _a === void 0 ? void 0 : _a.title,
        message: (_b = osNotification === null || osNotification === void 0 ? void 0 : osNotification.payload) === null || _b === void 0 ? void 0 : _b.body,
        payload: (_c = osNotification === null || osNotification === void 0 ? void 0 : osNotification.payload) === null || _c === void 0 ? void 0 : _c.additionalData,
        playerIds: osNotification.include_player_ids,
        segments: osNotification.included_segments,
    });
};
