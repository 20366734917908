var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useState } from 'react';
import BaseModal from '../components/modals/BaseModal';
import ConfirmModal from '../components/modals/ConfirmModal';
import AnnouncementModal from '../components/modals/AnnouncementModal';
import AnnouncementListModal from '../components/modals/AnnouncementListModal';
import PostModal from '../components/modals/PostModal';
import SettingsModal from '../components/modals/SettingsModal';
import ChangePostModal from '../components/modals/ChangePost';
import ChangeAnnouncementModal from '../components/modals/ChangeAnnouncement';
import PlaceModal from '../components/modals/PlaceModal';
import LoginModal from '../components/modals/LoginModal';
import ViewNotificationsModal from '../components/modals/ViewNotificationsModal';
import SendPushNotificationModal from '../components/modals/SendPushNotificationModal';
import NotificationHistoryModal from '../components/modals/NotificationHistoryModal';
var ModalContext = React.createContext({
    isOpen: false,
    show: function () { },
    hide: function () { },
    toggle: function () { return false; },
    use: function () { },
});
export var useModal = function () { return React.useContext(ModalContext); };
var _renderModal = function (modal) {
    switch (modal.name) {
        case 'confirm':
            return _jsx(ConfirmModal, __assign({}, modal.props), void 0);
        case 'announcement':
            return _jsx(AnnouncementModal, __assign({}, modal.props), void 0);
        case 'announcementList':
            return _jsx(AnnouncementListModal, __assign({}, modal.props), void 0);
        case 'post':
            return _jsx(PostModal, __assign({}, modal.props), void 0);
        case 'settings':
            return _jsx(SettingsModal, __assign({}, modal.props), void 0);
        case 'changePost':
            return _jsx(ChangePostModal, __assign({}, modal.props), void 0);
        case 'changeAnnouncement':
            return _jsx(ChangeAnnouncementModal, __assign({}, modal.props), void 0);
        case 'place':
            return _jsx(PlaceModal, __assign({}, modal.props), void 0);
        case 'login':
            return _jsx(LoginModal, __assign({}, modal.props), void 0);
        case 'view-notifications':
            return _jsx(ViewNotificationsModal, __assign({}, modal.props), void 0);
        case 'send-notification':
            return _jsx(SendPushNotificationModal, __assign({}, modal.props), void 0);
        case 'notification-history':
            return _jsx(NotificationHistoryModal, __assign({}, modal.props), void 0);
        default:
            return _jsx(_Fragment, {}, void 0);
    }
};
export var ModalProvider = function (_a) {
    var children = _a.children;
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var _c = useState(), Modal = _c[0], setModal = _c[1];
    var show = function (modal) {
        if (modal) {
            setIsOpen(false);
            setModal(modal);
        }
        setIsOpen(true);
    };
    var hide = function () { return setIsOpen(false); };
    var toggle = useCallback(function () {
        setIsOpen(!isOpen);
        return isOpen;
    }, [isOpen]);
    var use = function (modal) { return setModal(modal); };
    return (_jsxs(ModalContext.Provider, __assign({ value: {
            isOpen: isOpen,
            current: Modal,
            show: show,
            hide: hide,
            toggle: toggle,
            use: use,
        } }, { children: [_jsx(BaseModal, { children: Modal && _renderModal(Modal) }, void 0), children] }), void 0));
};
export default useModal;
