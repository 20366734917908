var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { tw } from 'twind';
import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import useModal from '../../hooks/useModal';
import { Colors, Styles } from '../../constants/constants-styles';
import Icon from '../Icon';
import useTheme from '../../hooks/useTheme';
export var ModalHeaderAction = function (_a) {
    var className = _a.className, icon = _a.icon, _b = _a.hidden, hidden = _b === void 0 ? false : _b, props = __rest(_a, ["className", "icon", "hidden"]);
    return (_jsx(_Fragment, { children: !hidden && (_jsx("button", __assign({ className: tw(Styles.transition, "focus:outline-none", "flex justify-between items-center space-x-2", props.disabled && tw(templateObject_1 || (templateObject_1 = __makeTemplateObject(["cursor-not-allowed opacity-50 bg-[", "]"], ["cursor-not-allowed opacity-50 bg-[", "]"])), Colors.block), className) }, props, { children: icon && _jsx(Icon, { small: true, as: icon, className: tw("inline") }, void 0) }), void 0)) }, void 0));
};
export var ModalHeader = function (_a) {
    var title = _a.title, subtitle = _a.subtitle, subtitleStyle = _a.subtitleStyle, actions = _a.actions;
    var modal = useModal();
    var theme = useTheme();
    return (_jsxs("div", __assign({ className: "flex flex-col space-y-2" }, { children: [_jsxs("section", __assign({ className: tw("flex flex-col") }, { children: [_jsxs("div", __assign({ className: tw("flex justify-between items-center") }, { children: [_jsxs("div", __assign({ className: tw("flex space-x-4") }, { children: [_jsx(Dialog.Title, __assign({ as: "h3", className: tw("text-xl lg:text-2xl font-bold text-[" + Colors.primary + "]") }, { children: title }), void 0), actions && actions.length > 0 && (_jsx("div", __assign({ className: tw("flex space-x-4") }, { children: actions.map(function (props) { return (_jsx(ModalHeaderAction, __assign({}, props), void 0)); }) }), void 0))] }), void 0), _jsx("button", __assign({ type: "button", className: tw(Styles.transition, "text-[" + theme.text + "]", "hover:text-[" + Colors.danger + "])", "focus:(outline-none text-[" + Colors.danger + "])"), onClick: function () { return modal.hide(); } }, { children: _jsx(Icon, { small: true, as: "close" }, void 0) }), void 0)] }), void 0), subtitle && typeof subtitle === 'string' && (_jsx(Dialog.Description, __assign({ as: "p", className: tw(Styles.font.inter, "font-italic", subtitleStyle === 'danger'
                            ? "text-[" + theme.danger + "]"
                            : "text-[" + theme.secondaryText + "]") }, { children: subtitle }), void 0)), subtitle &&
                        typeof subtitle === 'object' &&
                        subtitle.map(function (s) { return (_jsx(Dialog.Description, __assign({ as: "p", className: tw(Styles.font.inter, "font-italic text-[" + theme.secondaryText + "]") }, { children: s }), void 0)); })] }), void 0), _jsx("hr", { className: tw("border-[" + theme.divider + "]") }, void 0)] }), void 0));
};
export var ModalFooter = function (_a) {
    var children = _a.children, className = _a.className, props = __rest(_a, ["children", "className"]);
    return (_jsx("div", __assign({ className: tw("flex flex-col space-y-4 md:(flex-row space-y-0 space-x-4) pt-8", className) }, props, { children: children }), void 0));
};
var CenterElement = function () { return (
// This element is to trick the browser into centering the modal contents.
_jsx("span", __assign({ className: "inline-block h-screen align-middle", "aria-hidden": "true" }, { children: "\u200B" }), void 0)); };
export var BaseModal = function (_a) {
    var children = _a.children;
    var modal = useModal();
    var theme = useTheme();
    return (_jsx(Transition, __assign({ appear: true, show: modal.isOpen, as: Fragment }, { children: _jsx(Dialog, __assign({ as: "div", className: "fixed inset-0 z-50 overflow-y-auto safe-px-4", onClose: function () { return modal.hide(); } }, { children: _jsxs("div", __assign({ className: "min-h-screen text-center" }, { children: [_jsx(Transition.Child, __assign({ as: Fragment, enter: tw(Styles.duration, "ease-out"), enterFrom: "opacity-0", enterTo: "opacity-100", leave: tw(Styles.duration, "ease-in"), leaveFrom: "opacity-100", leaveTo: "opacity-0" }, { children: _jsx(Dialog.Overlay, { className: tw(templateObject_2 || (templateObject_2 = __makeTemplateObject(["fixed inset-0 transition-opacity bg-black bg-opacity-50"], ["fixed inset-0 transition-opacity bg-black bg-opacity-50"]))) }, void 0) }), void 0), _jsx(CenterElement, {}, void 0), _jsx(Transition.Child, __assign({ as: "div", enter: tw(Styles.duration, "ease-out"), enterFrom: "opacity-0 scale-95", enterTo: "opacity-100 scale-100", leave: tw(Styles.duration, "ease-in"), leaveFrom: "opacity-100 scale-100", leaveTo: "opacity-0 scale-95", className: tw("flex flex-col inline-block w-full max-w-4xl p-6 safe-my-8 space-y-4 overflow-hidden text-left align-middle transition-all transform bg-[" + theme.page + "] shadow-xl rounded-2xl") }, { children: children }), void 0)] }), void 0) }), void 0) }), void 0));
};
export default BaseModal;
var templateObject_1, templateObject_2;
