var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { tw } from 'twind';
export var Colors = {
    background: { light: '#FFFFFF', dark: '#101010' },
    block: '#F2F2F2',
    primary: '#1B76FF',
    light: '#E4EFFF',
    medium: '#C4C4C4',
    dark: '#303030',
    danger: '#DC2626',
    dangerLight: '#FFCBCB',
};
export var border = tw(templateObject_1 || (templateObject_1 = __makeTemplateObject(["border-4"], ["border-4"])));
var outline = {
    base: tw(templateObject_2 || (templateObject_2 = __makeTemplateObject(["focus:outline-none"], ["focus:outline-none"]))),
    focus: tw(templateObject_3 || (templateObject_3 = __makeTemplateObject(["focus:border-[", "]"], ["focus:border-[", "]"])), Colors.primary),
    hover: tw(templateObject_4 || (templateObject_4 = __makeTemplateObject(["hover:border-[", "]"], ["hover:border-[", "]"])), Colors.primary),
};
var duration = tw(templateObject_5 || (templateObject_5 = __makeTemplateObject(["motion-safe:(duration-200)"], ["motion-safe:(duration-200)"])));
export var Styles = {
    rounded: tw(templateObject_6 || (templateObject_6 = __makeTemplateObject(["rounded-2xl"], ["rounded-2xl"]))),
    duration: duration,
    transition: tw("motion-safe:(transition ease-in-out)", duration),
    hover: tw(templateObject_7 || (templateObject_7 = __makeTemplateObject(["hover:(", " ", " -translate-y-1)"], ["hover:(", " ", " -translate-y-1)"])), outline.base, outline.hover),
    focus: tw(templateObject_8 || (templateObject_8 = __makeTemplateObject(["focus:(", " ", " active:translate-y-1.5)"], ["focus:(", " ", " active:translate-y-1.5)"])), outline.base, outline.focus),
    border: {
        base: tw(templateObject_9 || (templateObject_9 = __makeTemplateObject(["", ""], ["", ""])), border),
        block: tw(templateObject_10 || (templateObject_10 = __makeTemplateObject(["", " border-[", "]"], ["", " border-[", "]"])), border, Colors.block),
        light: tw(templateObject_11 || (templateObject_11 = __makeTemplateObject(["", " border-[", "]"], ["", " border-[", "]"])), border, Colors.light),
    },
    outline: {
        base: outline.base,
        focus: outline.focus,
        hover: outline.hover,
    },
    font: {
        title: tw(templateObject_12 || (templateObject_12 = __makeTemplateObject(["font-poppins"], ["font-poppins"]))),
        body: tw(templateObject_13 || (templateObject_13 = __makeTemplateObject(["font-raleway"], ["font-raleway"]))),
        inter: tw(templateObject_14 || (templateObject_14 = __makeTemplateObject(["font-inter"], ["font-inter"]))),
    },
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14;
