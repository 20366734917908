var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { tw } from 'twind';
import useTheme from '../hooks/useTheme';
import { Styles } from '../constants/constants-styles';
export var Button = function (_a) {
    var primary = _a.primary, danger = _a.danger, children = _a.children, className = _a.className, props = __rest(_a, ["primary", "danger", "children", "className"]);
    var theme = useTheme();
    var getButtonStyles = function () {
        if (props.disabled) {
            return tw(templateObject_1 || (templateObject_1 = __makeTemplateObject(["bg-[", "] text-[", "] cursor-not-allowed"], ["bg-[", "] text-[", "] cursor-not-allowed"])), theme.disabled.bg, theme.disabled.text);
        }
        else if (primary) {
            return tw(templateObject_2 || (templateObject_2 = __makeTemplateObject(["bg-[", "] text-[", "]"], ["bg-[", "] text-[", "]"])), theme.primary, theme.primaryText);
        }
        else if (danger) {
            return tw(templateObject_3 || (templateObject_3 = __makeTemplateObject(["bg-[", "] text-[", "]"], ["bg-[", "] text-[", "]"])), theme.danger, theme.dangerText);
        }
        return tw(templateObject_4 || (templateObject_4 = __makeTemplateObject(["bg-[", "] text-[", "]"], ["bg-[", "] text-[", "]"])), theme.secondary, theme.primary);
    };
    return (_jsx("button", __assign({ className: tw("select-none motion-safe:(transition-[border-radius] transition-opacity)", Styles.transition, Styles.rounded, Styles.font.inter, "px-4 py-2 focus:outline-none justify-center", !props.disabled && tw(templateObject_5 || (templateObject_5 = __makeTemplateObject(["hover:opacity-75 focus:opacity-75 active:opacity-50"], ["hover:opacity-75 focus:opacity-75 active:opacity-50"]))), getButtonStyles(), className) }, props, { children: children }), void 0));
};
export default Button;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
