var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { tw } from 'twind';
import { ModalHeader, ModalFooter } from './BaseModal';
import useModal from '../../hooks/useModal';
import { Colors, Styles } from '../../constants/constants-styles';
import { useState } from 'react';
import { useParse } from '../../hooks/useParse';
import Icon from '../Icon';
import { IoTrashBinSharp } from 'react-icons/io5';
import TextField from '../TextField';
import { RadioGroup } from '@headlessui/react';
import useTheme from '../../hooks/useTheme';
import Button from '../Button';
export var ChangePostModal = function (_a) {
    var title = _a.title, description = _a.description, links = _a.links, post = _a.post;
    var modal = useModal();
    var _b = useState(title), newTitle = _b[0], setNewTitle = _b[1];
    var _c = useState(description), newDescription = _c[0], setNewDescription = _c[1];
    var newLink = '';
    var _d = useState(links !== null && links !== void 0 ? links : []), newLinks = _d[0], setNewLinks = _d[1];
    var topics = useParse().topics;
    var _e = useState(topics[0]), selectedTopic = _e[0], setSelectedTopic = _e[1];
    var handleLinkChange = function (e, index) {
        var updatedLinks = __spreadArray([], newLinks);
        //@ts-ignore
        updatedLinks[index] = e.target.value;
        setNewLinks(updatedLinks);
    };
    var theme = useTheme();
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { title: title !== null && title !== void 0 ? title : 'New Post', actions: post && [
                    {
                        icon: IoTrashBinSharp,
                        className: tw(templateObject_1 || (templateObject_1 = __makeTemplateObject(["text-red-600"], ["text-red-600"]))),
                        onClick: function () { return console.log(post === null || post === void 0 ? void 0 : post.id); },
                    },
                ] }, void 0), _jsxs("div", __assign({ className: tw(templateObject_2 || (templateObject_2 = __makeTemplateObject(["flex flex-row justify-between"], ["flex flex-row justify-between"]))) }, { children: [_jsxs("div", __assign({ className: tw(templateObject_3 || (templateObject_3 = __makeTemplateObject(["flex flex-col w-full"], ["flex flex-col w-full"]))) }, { children: [_jsx("p", __assign({ className: tw(templateObject_4 || (templateObject_4 = __makeTemplateObject(["font-bold"], ["font-bold"]))) }, { children: "Title" }), void 0), _jsx(TextField, { error: false, value: newTitle, onChange: function (e) { return setNewTitle(e.target.value); }, className: tw("w-11/12 resize-none rounded-lg text-lg focus:outline-none bg-[" + Colors.light + "]") }, void 0), _jsx("p", __assign({ className: tw(templateObject_5 || (templateObject_5 = __makeTemplateObject(["font-bold"], ["font-bold"]))) }, { children: "Topics" }), void 0), _jsx(RadioGroup, __assign({ value: selectedTopic, 
                                //@ts-ignore
                                onChange: setSelectedTopic, className: tw("pt-2", "flex flex-col sm:flex-row space-x-4") }, { children: topics.map(function (topic) { return (_jsx(RadioGroup.Option, __assign({ value: topic, className: function (_a) {
                                        var checked = _a.checked;
                                        return tw(Styles.rounded, Styles.transition, Styles.font.inter, Styles.hover, Styles.focus, "px-4 py-2", "flex justify-between items-center space-x-4", "select-none text-left font-semibold cursor-pointer", "border-4", checked
                                            ? "bg-[" + theme.secondary + "] border-[" + theme.secondary + "] text-[" + theme.primary + "]"
                                            : "border-[" + theme.border + "] text-[" + theme.text + "]");
                                    } }, { children: function () { return (_jsx(_Fragment, { children: _jsx("span", { children: topic.attributes.title }, void 0) }, void 0)); } }), void 0)); }) }), void 0), _jsx("p", __assign({ className: tw(templateObject_6 || (templateObject_6 = __makeTemplateObject(["font-bold"], ["font-bold"]))) }, { children: "Links" }), void 0), newLinks === null || newLinks === void 0 ? void 0 : newLinks.map(function (link, i) {
                                return (_jsx(TextField, { error: false, defaultValue: link, className: tw("w-11/12 resize-none rounded-lg text-lg focus:outline-none bg-[" + Colors.light + "] mb-2"), onChange: function (e) { return handleLinkChange(e, i); } }, i));
                            }), _jsx(Icon, { onClick: function () { return setNewLinks(__spreadArray(__spreadArray([], newLinks), [newLink])); }, as: 'plus', small: true, className: tw(templateObject_7 || (templateObject_7 = __makeTemplateObject(["self-end mr-8"], ["self-end mr-8"]))) }, void 0)] }), void 0), _jsxs("div", __assign({ className: tw(templateObject_8 || (templateObject_8 = __makeTemplateObject(["w-full"], ["w-full"]))) }, { children: [_jsx("p", __assign({ className: tw(templateObject_9 || (templateObject_9 = __makeTemplateObject(["font-bold"], ["font-bold"]))) }, { children: "Description" }), void 0), _jsx("textarea", { value: newDescription, onChange: function (e) { return setNewDescription(e.target.value); }, className: tw(Styles.rounded, Styles.font.body, Styles.transition, "border-black border-opacity-5 bg-transparent", "border-4 px-4 py-2", "focus-within:(border-[" + Colors.primary + "]) focus:outline-none", "text-lg text-left cursor-text font-bold text-[" + Colors.dark + "] placeholder-[" + Colors.medium + "] h-full w-full resize-none") }, void 0)] }), void 0)] }), void 0), _jsxs(ModalFooter, { children: [_jsx(Button, __assign({ onClick: function () {
                            console.log({
                                title: newTitle,
                                description: newDescription,
                                links: newLinks,
                            });
                            post &&
                                modal.show({
                                    name: 'post',
                                    props: { post: post },
                                });
                        }, className: tw(templateObject_10 || (templateObject_10 = __makeTemplateObject(["text-white! bg-[", "]!"], ["text-white! bg-[", "]!"])), Colors.primary) }, { children: "Save" }), void 0), _jsx(Button, __assign({ onClick: function () {
                            post &&
                                modal.show({
                                    name: 'post',
                                    props: { post: post },
                                });
                            !post && modal.show({ name: 'settings', props: {} });
                        } }, { children: "Cancel" }), void 0)] }, void 0)] }, void 0));
};
export default ChangePostModal;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
