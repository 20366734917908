var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { tw } from 'twind';
import PostCard from './PostCard';
import { FaSpinner } from 'react-icons/fa';
import useTheme from '../hooks/useTheme';
var PostSection = function (_a) {
    var posts = _a.posts, loading = _a.loading;
    var theme = useTheme();
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: tw(templateObject_1 || (templateObject_1 = __makeTemplateObject(["grid grid-cols-1 md:(grid-cols-2) 2xl:(grid-cols-3) safe-mt pb-4 mb-28 gap-x-4 gap-y-4"], ["grid grid-cols-1 md:(grid-cols-2) 2xl:(grid-cols-3) safe-mt pb-4 mb-28 gap-x-4 gap-y-4"]))) }, { children: posts.map(function (p, i) { return (_jsx(PostCard, { post: p }, i)); }) }), void 0), loading && (_jsx(FaSpinner, { size: 40, className: tw(templateObject_2 || (templateObject_2 = __makeTemplateObject(["text-[", "] self-center animate-spin"], ["text-[", "] self-center animate-spin"])), theme.text) }, void 0))] }, void 0));
};
export default PostSection;
var templateObject_1, templateObject_2;
