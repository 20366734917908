var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Transition } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { IoSettings } from 'react-icons/io5';
import { useLocation } from 'react-router';
import { tw } from 'twind';
import { Styles } from '../constants/constants-styles';
import useModal from '../hooks/useModal';
import useParse from '../hooks/useParse';
import useScrollHide from '../hooks/useScrollHide';
import useTheme from '../hooks/useTheme';
import NavItem, { NavModalItem } from './NavItem';
export var BottomNav = function () {
    var _a, _b, _c, _d;
    var theme = useTheme();
    var location = useLocation();
    var modal = useModal();
    var _e = useParse(), syncPosts = _e.syncPosts, posts = _e.posts, totalPosts = _e.totalPosts;
    var _f = useState(true), loading = _f[0], setLoading = _f[1];
    var _g = useScrollHide(), visible = _g.visible, bottom = _g.bottom;
    useEffect(function () {
        posts.length < totalPosts ? setLoading(true) : setLoading(false);
    }, [syncPosts, bottom]);
    return (_jsx(Transition, __assign({ show: visible || (bottom && !loading), as: "nav", enter: tw(Styles.duration, "ease-out"), enterFrom: tw(templateObject_1 || (templateObject_1 = __makeTemplateObject(["translate-y-24"], ["translate-y-24"]))), enterTo: tw(templateObject_2 || (templateObject_2 = __makeTemplateObject(["translate-y-0"], ["translate-y-0"]))), leave: tw(Styles.duration, "ease-in"), leaveFrom: tw(templateObject_3 || (templateObject_3 = __makeTemplateObject(["translate-y-0"], ["translate-y-0"]))), leaveTo: tw(templateObject_4 || (templateObject_4 = __makeTemplateObject(["translate-y-24"], ["translate-y-24"]))), className: tw("rounded-t-2xl w-full flex safe-pb-2 fixed bottom-0 safe-px-2 lg:hidden", "bg-[" + theme.page + "]") }, { children: _jsxs("ul", __assign({ className: tw(templateObject_5 || (templateObject_5 = __makeTemplateObject(["flex w-full justify-evenly "], ["flex w-full justify-evenly "]))) }, { children: [_jsx("li", { children: _jsx(NavItem, __assign({ className: tw(templateObject_6 || (templateObject_6 = __makeTemplateObject(["flex flex-col"], ["flex flex-col"]))), to: "/about", icon: 'home', active: !(modal.isOpen && ((_a = modal.current) === null || _a === void 0 ? void 0 : _a.name) === 'settings') &&
                            location.pathname.split('/')[1] === 'about' }, { children: "About" }), void 0) }, void 0), _jsx("li", { children: _jsx(NavItem, __assign({ className: tw(templateObject_7 || (templateObject_7 = __makeTemplateObject(["flex flex-col"], ["flex flex-col"]))), to: "/nearme", icon: 'compass', active: !(modal.isOpen && ((_b = modal.current) === null || _b === void 0 ? void 0 : _b.name) === 'settings') &&
                            location.pathname.split('/')[1] === 'nearme' }, { children: "Near Me" }), void 0) }, void 0), _jsx("li", { children: _jsx(NavItem, __assign({ className: tw(templateObject_8 || (templateObject_8 = __makeTemplateObject(["flex flex-col"], ["flex flex-col"]))), to: "/downloads", icon: 'download', active: !(modal.isOpen && ((_c = modal.current) === null || _c === void 0 ? void 0 : _c.name) === 'settings') &&
                            location.pathname.split('/')[1] === 'downloads' }, { children: "Downloads" }), void 0) }, void 0), _jsx("li", { children: _jsx(NavModalItem, __assign({ icon: IoSettings, className: tw(templateObject_9 || (templateObject_9 = __makeTemplateObject(["flex flex-col"], ["flex flex-col"]))), onClick: function () { return modal.show({ name: 'settings', props: {} }); }, active: modal.isOpen && ((_d = modal.current) === null || _d === void 0 ? void 0 : _d.name) === 'settings' }, { children: "Settings" }), void 0) }, void 0)] }), void 0) }), void 0));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
