import { useCallback, useEffect } from 'react';
export default function useScroll(cb, deps, options) {
    var callback = useCallback(function () { return cb(); }, deps !== null && deps !== void 0 ? deps : []);
    useEffect(function () {
        window.addEventListener('scroll', callback, options);
        return function () {
            window.removeEventListener('scroll', callback);
        };
    }, [callback, options]);
}
