var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { tw } from 'twind';
import useTheme from '../hooks/useTheme';
export var SectionTitle = function (_a) {
    var disabled = _a.disabled, children = _a.children;
    var theme = useTheme();
    return (_jsx("h4", __assign({ className: tw("font-bold text-lg text-[" + theme.text + "]", disabled && tw(templateObject_1 || (templateObject_1 = __makeTemplateObject(["opacity-25"], ["opacity-25"])))) }, { children: children }), void 0));
};
export var SectionSubtitle = function (_a) {
    var disabled = _a.disabled, children = _a.children;
    var theme = useTheme();
    return (_jsx("h5", __assign({ className: tw("text-[" + theme.secondaryText + "]", disabled && tw(templateObject_2 || (templateObject_2 = __makeTemplateObject(["opacity-25"], ["opacity-25"])))) }, { children: children }), void 0));
};
export var SectionMessage = function (_a) {
    var disabled = _a.disabled, children = _a.children;
    var theme = useTheme();
    return (_jsx("p", __assign({ className: tw("text-[" + theme.text + "]", disabled && tw(templateObject_3 || (templateObject_3 = __makeTemplateObject(["opacity-25"], ["opacity-25"])))) }, { children: children }), void 0));
};
export var Section = function (_a) {
    var sectionTitle = _a.sectionTitle, sectionSubtitle = _a.sectionSubtitle, sectionMessage = _a.sectionMessage, _b = _a.row, row = _b === void 0 ? 'collapse' : _b, disabled = _a.disabled, children = _a.children, className = _a.className, props = __rest(_a, ["sectionTitle", "sectionSubtitle", "sectionMessage", "row", "disabled", "children", "className"]);
    var getSectionStyles = function () {
        switch (row) {
            case 'always':
                return tw(templateObject_4 || (templateObject_4 = __makeTemplateObject(["flex-row items-center justify-between"], ["flex-row items-center justify-between"])));
            case 'collapse':
                return tw("flex-col items-start justify-between", "md:(flex-row items-center)");
            case 'never':
                return tw(templateObject_5 || (templateObject_5 = __makeTemplateObject(["flex-col items-start justify-between"], ["flex-col items-start justify-between"])));
        }
    };
    return (_jsxs("section", __assign({ className: tw("flex", getSectionStyles(), className) }, props, { children: [(sectionTitle || sectionSubtitle || sectionMessage) && (_jsxs("div", { children: [sectionTitle && (_jsx(SectionTitle, __assign({ disabled: disabled }, { children: sectionTitle }), void 0)), sectionSubtitle && (_jsx(SectionSubtitle, __assign({ disabled: disabled }, { children: sectionSubtitle }), void 0)), sectionMessage && (_jsx(SectionMessage, __assign({ disabled: disabled }, { children: sectionMessage }), void 0))] }, void 0)), _jsx("div", __assign({ className: tw("pl-4 pt-2 flex flex-col space-y-4 w-full", "md:(p-0 flex-row items-center space-x-2 space-y-0 w-auto)") }, { children: children }), void 0)] }), void 0));
};
export default Section;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
