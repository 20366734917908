import { reduceToObj } from './reduce';
export var dedupeUpdates = function (_a) {
    var _original = _a.original, _updates = _a.updates, _getKey = _a.getKey, _b = _a.prefer, prefer = _b === void 0 ? 'update' : _b;
    var original = reduceToObj(_original, _getKey);
    for (var _i = 0, _updates_1 = _updates; _i < _updates_1.length; _i++) {
        var u = _updates_1[_i];
        var key = _getKey(u);
        if (!original[key] || prefer === 'update') {
            original[_getKey(u)] = u;
        }
    }
    return Object.values(original);
};
