var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { setup, css } from 'twind/css';
var config = {
    mode: 'warn',
    plugins: {
        'hide-scroll': css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      /* Chrome, Safari, Opera */\n      &::-webkit-scrollbar {\n        display: none;\n      }\n\n      /* IE, Edge */\n      -ms-overflow-style: none;\n\n      /* Firefox */\n      scrollbar-width: none;\n    "], ["\n      /* Chrome, Safari, Opera */\n      &::-webkit-scrollbar {\n        display: none;\n      }\n\n      /* IE, Edge */\n      -ms-overflow-style: none;\n\n      /* Firefox */\n      scrollbar-width: none;\n    "]))),
        'safe-p': function (parts, _a) {
            var _b;
            var t = _a.theme;
            var p = t('padding', (_b = parts[0]) !== null && _b !== void 0 ? _b : '0');
            return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        padding: ", ";\n        @supports (padding: max(0px)) {\n          padding-top: max(", ", env(safe-area-inset-top));\n          padding-bottom: max(", ", env(safe-area-inset-bottom));\n          padding-left: max(", ", env(safe-area-inset-left));\n          padding-right: max(", ", env(safe-area-inset-right));\n        }\n      "], ["\n        padding: ", ";\n        @supports (padding: max(0px)) {\n          padding-top: max(", ", env(safe-area-inset-top));\n          padding-bottom: max(", ", env(safe-area-inset-bottom));\n          padding-left: max(", ", env(safe-area-inset-left));\n          padding-right: max(", ", env(safe-area-inset-right));\n        }\n      "])), p, p, p, p, p);
        },
        'safe-py': function (parts, _a) {
            var _b;
            var t = _a.theme;
            var p = t('padding', (_b = parts[0]) !== null && _b !== void 0 ? _b : '0');
            return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        padding-top: ", ";\n        padding-bottom: ", ";\n        @supports (padding: max(0px)) {\n          padding-top: max(", ", env(safe-area-inset-top));\n          padding-bottom: max(", ", env(safe-area-inset-bottom));\n        }\n      "], ["\n        padding-top: ", ";\n        padding-bottom: ", ";\n        @supports (padding: max(0px)) {\n          padding-top: max(", ", env(safe-area-inset-top));\n          padding-bottom: max(", ", env(safe-area-inset-bottom));\n        }\n      "])), p, p, p, p);
        },
        'safe-pt': function (parts, _a) {
            var _b;
            var t = _a.theme;
            var p = t('padding', (_b = parts[0]) !== null && _b !== void 0 ? _b : '0');
            return css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n        padding-top: ", ";\n        @supports (padding: max(0px)) {\n          padding-top: max(", ", env(safe-area-inset-top));\n        }\n      "], ["\n        padding-top: ", ";\n        @supports (padding: max(0px)) {\n          padding-top: max(", ", env(safe-area-inset-top));\n        }\n      "])), p, p);
        },
        'safe-pb': function (parts, _a) {
            var _b;
            var t = _a.theme;
            var p = t('padding', (_b = parts[0]) !== null && _b !== void 0 ? _b : '0');
            return css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n        padding-bottom: ", ";\n        @supports (padding: max(0px)) {\n          padding-bottom: max(", ", env(safe-area-inset-bottom));\n        }\n      "], ["\n        padding-bottom: ", ";\n        @supports (padding: max(0px)) {\n          padding-bottom: max(", ", env(safe-area-inset-bottom));\n        }\n      "])), p, p);
        },
        'safe-px': function (parts, _a) {
            var _b;
            var t = _a.theme;
            var p = t('padding', (_b = parts[0]) !== null && _b !== void 0 ? _b : '0');
            return css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n        padding-left: ", ";\n        padding-right: ", ";\n        @supports (padding: max(0px)) {\n          padding-left: max(", ", env(safe-area-inset-left));\n          padding-right: max(", ", env(safe-area-inset-right));\n        }\n      "], ["\n        padding-left: ", ";\n        padding-right: ", ";\n        @supports (padding: max(0px)) {\n          padding-left: max(", ", env(safe-area-inset-left));\n          padding-right: max(", ", env(safe-area-inset-right));\n        }\n      "])), p, p, p, p);
        },
        'safe-m': function (parts, _a) {
            var _b;
            var t = _a.theme;
            var m = t('margin', (_b = parts[0]) !== null && _b !== void 0 ? _b : '0');
            return css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n        margin: ", ";\n        @supports (margin: max(0px)) {\n          margin-top: max(", ", env(safe-area-inset-top));\n          margin-bottom: max(", ", env(safe-area-inset-bottom));\n          margin-left: max(", ", env(safe-area-inset-left));\n          margin-right: max(", ", env(safe-area-inset-right));\n        }\n      "], ["\n        margin: ", ";\n        @supports (margin: max(0px)) {\n          margin-top: max(", ", env(safe-area-inset-top));\n          margin-bottom: max(", ", env(safe-area-inset-bottom));\n          margin-left: max(", ", env(safe-area-inset-left));\n          margin-right: max(", ", env(safe-area-inset-right));\n        }\n      "])), m, m, m, m, m);
        },
        'safe-my': function (parts, _a) {
            var _b;
            var t = _a.theme;
            var m = t('margin', (_b = parts[0]) !== null && _b !== void 0 ? _b : '0');
            return css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n        margin-top: ", ";\n        margin-bottom: ", ";\n        @supports (margin: max(0px)) {\n          margin-top: max(", ", env(safe-area-inset-top));\n          margin-bottom: max(", ", env(safe-area-inset-bottom));\n        }\n      "], ["\n        margin-top: ", ";\n        margin-bottom: ", ";\n        @supports (margin: max(0px)) {\n          margin-top: max(", ", env(safe-area-inset-top));\n          margin-bottom: max(", ", env(safe-area-inset-bottom));\n        }\n      "])), m, m, m, m);
        },
        'safe-mt': function (parts, _a) {
            var _b;
            var t = _a.theme;
            var m = t('margin', (_b = parts[0]) !== null && _b !== void 0 ? _b : '0');
            return css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n        margin-top: ", ";\n        @supports (margin: max(0px)) {\n          margin-top: max(", ", env(safe-area-inset-top));\n        }\n      "], ["\n        margin-top: ", ";\n        @supports (margin: max(0px)) {\n          margin-top: max(", ", env(safe-area-inset-top));\n        }\n      "])), m, m);
        },
        'safe-mx': function (parts, _a) {
            var _b;
            var t = _a.theme;
            var m = t('margin', (_b = parts[0]) !== null && _b !== void 0 ? _b : '0');
            return css(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n        margin-left: ", ";\n        margin-right: ", ";\n        @supports (margin: max(0px)) {\n          margin-left: max(", ", env(safe-area-inset-left));\n          margin-right: max(", ", env(safe-area-inset-right));\n        }\n      "], ["\n        margin-left: ", ";\n        margin-right: ", ";\n        @supports (margin: max(0px)) {\n          margin-left: max(", ", env(safe-area-inset-left));\n          margin-right: max(", ", env(safe-area-inset-right));\n        }\n      "])), m, m, m, m);
        },
        'rm-highlight': css(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n      -webkit-tap-highlight-color: transparent;\n    "], ["\n      -webkit-tap-highlight-color: transparent;\n    "]))),
    },
    theme: {
        extend: {
            fontFamily: {
                poppins: ['Poppins'],
                raleway: ['Raleway'],
                inter: ['Inter'],
            },
        },
        fontFamily: {
            poppins: ['Poppins'],
            raleway: ['Raleway'],
            inter: ['Inter'],
        },
    },
};
setup(config);
export default config;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;
