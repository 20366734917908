var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { tw } from 'twind';
import { Styles } from '../constants/constants-styles';
import useTheme from '../hooks/useTheme';
export var TextFieldLabel = function (_a) {
    var className = _a.className, children = _a.children, danger = _a.danger, props = __rest(_a, ["className", "children", "danger"]);
    var theme = useTheme();
    return (_jsx("label", __assign({ className: tw(Styles.font.body, "text-xl font-bold text-[" + theme.text + "]", danger && tw(templateObject_1 || (templateObject_1 = __makeTemplateObject(["text-[", "]"], ["text-[", "]"])), theme.danger), className) }, props, { children: children }), void 0));
};
export var TextField = function (_a) {
    var error = _a.error, className = _a.className, props = __rest(_a, ["error", "className"]);
    var theme = useTheme();
    return (_jsx("input", __assign({ className: tw(Styles.rounded, Styles.font.body, Styles.transition, error
            ? "border-[" + theme.danger + "] bg-[" + theme.dangerText + "] text-[" + theme.danger + "] placeholder-[" + theme.dangerPlaceholder + "]"
            : "border-[" + theme.border + "] bg-transparent text-[" + theme.text + "] placeholder-[" + theme.placeholder + "]", "border-4 px-4 py-2", "focus-within:(border-[" + theme.primary + "]) focus:outline-none", "text-lg text-left cursor-text font-bold", className) }, props), void 0));
};
export default TextField;
var templateObject_1;
