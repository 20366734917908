var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import toast from 'react-hot-toast';
import { IoSettings } from 'react-icons/io5';
import { useLocation } from 'react-router';
import { tw } from 'twind';
import { AppName } from '../constants/constants-general';
import { Colors, Styles } from '../constants/constants-styles';
import { Toasts } from '../constants/constants-toast';
import useAnnouncements from '../hooks/useAnnouncements';
import { useDownloads } from '../hooks/useDownloads';
import useModal from '../hooks/useModal';
import { useNotifications } from '../hooks/notifications/useNotifications';
import useTheme from '../hooks/useTheme';
import Icon from './Icon';
import NavItem, { NavModalItem } from './NavItem';
import BaseToast from './toasts/BaseToast';
export var NavContainer = function (_a) {
    var children = _a.children;
    var theme = useTheme();
    return (_jsx("nav", __assign({ className: tw("fixed top-0 left-0 z-10", "h-20 w-full bg-[" + theme.page + "]") }, { children: children }), void 0));
};
var Nav = function () {
    var _a, _b, _c, _d, _e, _f, _g;
    var _h = useDownloads(), downloads = _h.downloads, clearDownloads = _h.clearDownloads;
    var announcements = useAnnouncements().announcements;
    var location = useLocation();
    var pageTitle = location.pathname.split('/')[1];
    var modal = useModal();
    var theme = useTheme();
    var notifications = useNotifications();
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: tw("bg-[" + theme.page + "] flex justify-between items-center safe-mt", "lg:(h-auto p-6)") }, { children: [_jsxs(NavItem, __assign({ isTitle: true, to: '/', className: tw(Styles.transition, Styles.hover, Styles.focus, "space-x-6") }, { children: [_jsx("img", { alt: "IFA Logo", src: "./images/Small-Mask-Wheel.png", className: tw(templateObject_1 || (templateObject_1 = __makeTemplateObject(["w-16 h-16 lg:hidden"], ["w-16 h-16 lg:hidden"]))) }, void 0), _jsx("img", { alt: "IFA Logo", src: "./images/Large-Mask-Wheel.png", className: tw(templateObject_2 || (templateObject_2 = __makeTemplateObject(["hidden w-20 h-20 lg:block"], ["hidden w-20 h-20 lg:block"]))) }, void 0), _jsx("h1", __assign({ className: tw(Styles.font.title, "text-[" + Colors.primary + "] text-xl lg:text-3xl font-bold") }, { children: AppName }), void 0)] }), void 0), _jsxs("ul", __assign({ className: tw(templateObject_3 || (templateObject_3 = __makeTemplateObject(["hidden lg:(flex items-center space-x-8)"], ["hidden lg:(flex items-center space-x-8)"]))) }, { children: [_jsx("li", { children: _jsx(NavItem, __assign({ to: "/about", icon: 'home', active: !(modal.isOpen && ((_a = modal.current) === null || _a === void 0 ? void 0 : _a.name) === 'settings') &&
                                    pageTitle === 'about', className: tw(templateObject_4 || (templateObject_4 = __makeTemplateObject(["flex items-center w-full lg:flex-col xl:flex-row"], ["flex items-center w-full lg:flex-col xl:flex-row"]))) }, { children: "About" }), void 0) }, void 0), _jsx("li", { children: _jsx(NavItem, __assign({ to: "/nearme", icon: 'compass', active: !(modal.isOpen &&
                                    (((_b = modal.current) === null || _b === void 0 ? void 0 : _b.name) === 'settings' ||
                                        ((_c = modal.current) === null || _c === void 0 ? void 0 : _c.name) === 'notification-history')) && location.pathname.split('/')[1] === 'nearme', className: tw(templateObject_5 || (templateObject_5 = __makeTemplateObject(["flex-col items-center w-full xl:flex-row"], ["flex-col items-center w-full xl:flex-row"]))) }, { children: "Near Me" }), void 0) }, void 0), _jsx("li", { children: _jsx(NavItem, __assign({ to: "/downloads", icon: 'download', active: !(modal.isOpen &&
                                    (((_d = modal.current) === null || _d === void 0 ? void 0 : _d.name) === 'settings' ||
                                        ((_e = modal.current) === null || _e === void 0 ? void 0 : _e.name) === 'notification-history')) && pageTitle === 'downloads', className: tw(templateObject_6 || (templateObject_6 = __makeTemplateObject(["flex-col items-center w-full xl:flex-row"], ["flex-col items-center w-full xl:flex-row"]))) }, { children: "Downloads" }), void 0) }, void 0)] }), void 0), _jsxs("ul", __assign({ className: tw(templateObject_7 || (templateObject_7 = __makeTemplateObject(["hidden lg:(flex items-center space-x-2) xl:(space-x-4)"], ["hidden lg:(flex items-center space-x-2) xl:(space-x-4)"]))) }, { children: [_jsx("li", { children: _jsx(NavModalItem, __assign({ icon: 'notification', className: tw("flex-col w-full items-center xl:flex-row"), onClick: function () {
                                    return modal.show({ name: 'notification-history', props: {} });
                                }, active: modal.isOpen && ((_f = modal.current) === null || _f === void 0 ? void 0 : _f.name) === 'notification-history' }, { children: "Notifications" }), void 0) }, void 0), _jsx("li", { children: _jsx(NavModalItem, __assign({ icon: IoSettings, className: tw("flex-col w-full items-center xl:flex-row"), onClick: function () { return modal.show({ name: 'settings', props: {} }); }, active: modal.isOpen && ((_g = modal.current) === null || _g === void 0 ? void 0 : _g.name) === 'settings' }, { children: "Settings" }), void 0) }, void 0)] }), void 0), _jsxs("div", __assign({ className: tw("flex space-x-2 lg:hidden") }, { children: [pageTitle === 'downloads' && downloads.length > 0 && (_jsx("button", __assign({ "aria-label": "clear-button", className: tw(Styles.transition, Styles.rounded, Styles.hover, Styles.focus, "flex items-center space-x-6 p-2 text-[" + theme.danger + "]", "hover:text-[" + Colors.danger + "] focus:text-[" + Colors.danger + "]"), onClick: function () {
                                modal.show({
                                    name: 'confirm',
                                    props: {
                                        headerOptions: {
                                            title: 'Delete all downloaded posts?',
                                        },
                                        onConfirm: function () {
                                            modal.hide();
                                            clearDownloads();
                                            toast.custom(function (t) { return (_jsx(BaseToast, __assign({}, t, { theme: theme, iconName: "offline", className: tw("text-[" + theme.dangerText + "] bg-[" + theme.danger + "]"), iconClassName: tw("text-[" + theme.dangerText + "]") }, { children: _jsx("h1", { children: "Deleted Downloaded Posts!" }, void 0) }), void 0)); }, {
                                                id: Toasts.clearDownloads,
                                            });
                                        },
                                        icon: 'offline',
                                    },
                                });
                            } }, { children: _jsx(Icon, { as: 'trash' }, void 0) }), void 0)), notifications.all && notifications.all.length > 0 && (_jsx("button", __assign({ className: tw(Styles.transition, Styles.rounded, Styles.hover, Styles.focus, "flex items-center space-x-6 p-2 text-[" + theme.text + "]", "hover:text-[" + Colors.primary + "] focus:text-[" + Colors.primary + "]"), onClick: function () {
                                return modal.show({
                                    name: 'notification-history',
                                    props: {},
                                });
                            } }, { children: _jsx(Icon, { as: 'notification' }, void 0) }), void 0)), announcements && announcements.length > 0 && (_jsx("button", __assign({ className: tw(Styles.transition, Styles.rounded, Styles.hover, Styles.focus, "flex items-center space-x-6 p-2 text-[" + theme.text + "]", "hover:text-[" + Colors.primary + "] focus:text-[" + Colors.primary + "]"), onClick: function () {
                                return modal.show({
                                    name: 'announcementList',
                                    props: {},
                                });
                            } }, { children: _jsx(Icon, { as: 'announcement' }, void 0) }), void 0))] }), void 0)] }), void 0) }, void 0));
};
export default Nav;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
