var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import * as Parse from 'parse';
import useInterval from '@use-it/interval';
import useSettings from './useSettings';
import { dedupeUpdates } from '../utils/dedupe';
var ParseContext = React.createContext({
    initialized: false,
    connected: false,
    client: {},
    authenticated: false,
    totalPosts: 0,
    posts: [],
    topics: [],
    login: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, ({})];
    }); }); },
    logout: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, ({})];
    }); }); },
    syncPosts: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/];
    }); }); },
    syncTopics: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/];
    }); }); },
    getPlaces: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, []];
    }); }); },
    setPosts: function () { },
    updatePost: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/];
    }); }); },
});
var topicQuery = new Parse.Query('Topic');
// TODO: move post and topic syncing to their own hooks
export var ParseProvider = function (_a) {
    var children = _a.children;
    var _b = useState(false), initialized = _b[0], setInitialized = _b[1];
    var _c = useState(false), connected = _c[0], setConnected = _c[1];
    var _d = useState(), client = _d[0], setClient = _d[1];
    var _e = useState(false), authenticated = _e[0], setAuthenticated = _e[1];
    var _f = useState(0), totalPosts = _f[0], setTotalPosts = _f[1];
    var _g = useState([]), posts = _g[0], setPosts = _g[1];
    var _h = useState([]), topics = _h[0], setTopics = _h[1];
    var _j = useState(), error = _j[0], setError = _j[1];
    var _k = useState(), postLiveQuerySubscription = _k[0], setPostLiveQuerySubscription = _k[1];
    var settings = useSettings();
    var refreshAuthenticated = function () {
        setAuthenticated(Boolean(Parse.User.current()));
    };
    useInterval(function () { return refreshAuthenticated(); }, 1000 * 10);
    useEffect(function () {
        if (initialized) {
            refreshAuthenticated();
        }
    }, [initialized, settings]);
    var login = function (username, password) { return __awaiter(void 0, void 0, void 0, function () {
        var user, sessionToken;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Parse.User.logIn(username, password)];
                case 1:
                    user = _a.sent();
                    sessionToken = user.getSessionToken();
                    if (sessionToken) {
                        settings.update({ sessionToken: sessionToken });
                    }
                    return [2 /*return*/, user];
            }
        });
    }); };
    var sessionLogin = function () { return __awaiter(void 0, void 0, void 0, function () {
        var err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(settings.sessionToken &&
                        settings.sessionToken.length > 0 &&
                        !authenticated)) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, Parse.User.become(settings.sessionToken)];
                case 2:
                    _a.sent();
                    refreshAuthenticated();
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    settings.update({ sessionToken: '' });
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var logout = function () { return __awaiter(void 0, void 0, void 0, function () {
        var user;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, Parse.User.logOut()];
                case 1:
                    user = _a.sent();
                    settings.update({ sessionToken: '' });
                    return [2 /*return*/, user];
            }
        });
    }); };
    var getFullAmountOfPosts = function () { return __awaiter(void 0, void 0, void 0, function () {
        var allPosts, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, new Parse.Query('Post').findAll()];
                case 1:
                    allPosts = _a.sent();
                    setTotalPosts(allPosts.length);
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.log(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var syncPosts = function (skip) {
        if (skip === void 0) { skip = 0; }
        return __awaiter(void 0, void 0, void 0, function () {
            var newPosts_1, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, new Parse.Query('Post')
                                .limit(10)
                                .skip(skip)
                                .descending('updatedAt')
                                .includeAll()
                                .find()];
                    case 1:
                        newPosts_1 = _a.sent();
                        setPosts(function (posts) {
                            return dedupeUpdates({
                                original: posts,
                                updates: newPosts_1,
                                getKey: function (p) { return p.id; },
                            });
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        console.error(error_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    var syncTopics = function (skip) {
        if (skip === void 0) { skip = 0; }
        return __awaiter(void 0, void 0, void 0, function () {
            var newTopics_1, error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, topicQuery
                                .limit(10)
                                .skip(skip)
                                .descending('updatedAt')
                                .includeAll()
                                .find()];
                    case 1:
                        newTopics_1 = _a.sent();
                        setTopics(function (topics) {
                            return dedupeUpdates({
                                original: topics,
                                updates: newTopics_1,
                                getKey: function (t) { return t.id; },
                            });
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        error_3 = _a.sent();
                        console.error(error_3);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    var getPlaces = function (options) { return __awaiter(void 0, void 0, Promise, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Parse.Cloud.run('getPlaces', options)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    // on fetch, if there are duplicate values we need to remove them
    var seen = new Set();
    var hasDuplicates = posts.some(function (currentObject) {
        return seen.size === seen.add(currentObject.id).size;
    });
    if (hasDuplicates) {
        var filteredArray = posts.filter(function (obj, index) {
            return posts.map(function (object) { return object.id; }).indexOf(obj.id) === index;
        });
        setPosts(filteredArray);
    }
    var updatePost = function (postId, editedFields) { return __awaiter(void 0, void 0, void 0, function () {
        var exists, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, new Parse.Query('Post').get(postId)];
                case 1:
                    exists = _a.sent();
                    if (exists) {
                        exists.set('title', editedFields.title);
                        exists.set('description', editedFields.description);
                        exists.set('links', editedFields.links);
                    }
                    return [2 /*return*/, exists.save()];
                case 2:
                    error_4 = _a.sent();
                    console.log(error_4);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (connected) {
            refreshPostLiveQuery();
            return function () {
                if (postLiveQuerySubscription) {
                    postLiveQuerySubscription.unsubscribe();
                    setPostLiveQuerySubscription(undefined);
                }
            };
        }
    }, [posts, connected]);
    var createPostHandler = useCallback(function (post) {
        var exists = posts.findIndex(function (obj) { return obj.id === post.id; });
        if (exists === -1) {
            setPosts(function (posts) { return __spreadArray(__spreadArray([], posts), [post]); });
        }
        setTotalPosts(totalPosts + 1);
    }, [posts, totalPosts]);
    var updatePostHandler = useCallback(function (updatedPost) {
        console.log('posts length: ', posts.length);
        setPosts(posts.map(function (post) { return (post.id === updatedPost.id ? updatedPost : post); }));
    }, [posts]);
    var deletePostHandler = useCallback(function (post) {
        var index = posts.findIndex(function (obj) { return obj.id === post.id; });
        if (index !== -1) {
            posts.splice(index, 1);
            setPosts(posts);
        }
        setTotalPosts(totalPosts - 1);
    }, [posts, totalPosts]);
    var refreshPostLiveQuery = useCallback(function () {
        if (postLiveQuerySubscription && client) {
            postLiveQuerySubscription.unsubscribe();
            var subscription = client.subscribe(new Parse.Query('Post'));
            subscription.on('create', function (post) {
                return createPostHandler(post);
            });
            subscription.on('update', function (post) {
                return updatePostHandler(post);
            });
            subscription.on('delete', function (post) {
                return deletePostHandler(post);
            });
            setPostLiveQuerySubscription(subscription);
        }
    }, [posts, client, createPostHandler, updatePostHandler, deletePostHandler]);
    var initializeServer = function () { return __awaiter(void 0, void 0, void 0, function () {
        var PARSE_APPLICATION_ID, PARSE_HOST_URL, PARSE_JAVASCRIPT_KEY, client;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    PARSE_APPLICATION_ID = process.env.REACT_APP_PARSE_APPLICATION_ID;
                    PARSE_HOST_URL = process.env.REACT_APP_PARSE_HOST_URL;
                    PARSE_JAVASCRIPT_KEY = process.env.REACT_APP_PARSE_JAVASCRIPT_KEY;
                    Parse.initialize(PARSE_APPLICATION_ID !== null && PARSE_APPLICATION_ID !== void 0 ? PARSE_APPLICATION_ID : '', PARSE_JAVASCRIPT_KEY);
                    // @ts-ignore
                    Parse.serverURL = PARSE_HOST_URL !== null && PARSE_HOST_URL !== void 0 ? PARSE_HOST_URL : '';
                    Parse.enableLocalDatastore();
                    client = new Parse.LiveQueryClient({
                        applicationId: PARSE_APPLICATION_ID,
                        serverURL: process.env.REACT_APP_LIVE_QUERY_URL,
                        javascriptKey: PARSE_JAVASCRIPT_KEY,
                    });
                    client.open();
                    client.on('open', function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setConnected(true);
                            getFullAmountOfPosts();
                            return [2 /*return*/];
                        });
                    }); });
                    client.on('error', function (error) {
                        console.error('A live query client error has occurred', error);
                        setConnected(false);
                        setError(error);
                    });
                    return [4 /*yield*/, syncPosts()];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, syncTopics()];
                case 2:
                    _a.sent();
                    setInitialized(true);
                    setClient(client);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        initializeServer();
    }, []);
    useEffect(function () {
        sessionLogin();
    }, [settings.initialized]);
    return (_jsx(ParseContext.Provider, __assign({ value: {
            initialized: initialized,
            connected: connected,
            client: client,
            authenticated: authenticated,
            posts: posts,
            topics: topics,
            totalPosts: totalPosts,
            login: login,
            logout: logout,
            syncPosts: syncPosts,
            syncTopics: syncTopics,
            getPlaces: getPlaces,
            setPosts: setPosts,
            error: error,
            updatePost: updatePost,
        } }, { children: children }), void 0));
};
export var useParse = function () { return React.useContext(ParseContext); };
export default useParse;
