var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useMediaQuery } from '@react-hook/media-query';
import React, { useEffect, useState } from 'react';
import useSettings from './useSettings';
import { tw } from 'twind';
import { DarkTheme, LightTheme } from '../types/theme';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style as StatusBarStyle } from '@capacitor/status-bar';
var ThemeContext = React.createContext(__assign({ _theme: { value: 'system', dark: false } }, LightTheme));
export var useTheme = function () { return React.useContext(ThemeContext); };
{
    /* TODO: remove system option for android and set default to light */
}
export var ThemeProvider = function (_a) {
    var children = _a.children;
    var theme = useSettings().theme;
    var systemDarkModeEnabled = useMediaQuery('(prefers-color-scheme: dark)');
    var _b = useState(systemDarkModeEnabled), darkModeEnabled = _b[0], setDarkModeEnabled = _b[1];
    var _c = useState(LightTheme), styles = _c[0], setStyles = _c[1];
    useEffect(function () {
        if (theme === 'system') {
            setDarkModeEnabled(systemDarkModeEnabled);
        }
        else {
            setDarkModeEnabled(theme === 'dark');
        }
    }, [theme, systemDarkModeEnabled]);
    useEffect(function () {
        var newStyles = darkModeEnabled ? DarkTheme : LightTheme;
        if (document.body) {
            document.body.className = tw(templateObject_1 || (templateObject_1 = __makeTemplateObject(["bg-[", "]"], ["bg-[", "]"])), newStyles.page);
        }
        setStyles(newStyles);
        if (Capacitor.isPluginAvailable('StatusBar')) {
            StatusBar.setStyle({
                style: darkModeEnabled ? StatusBarStyle.Dark : StatusBarStyle.Light,
            });
            if (Capacitor.getPlatform() !== 'ios') {
                StatusBar.setBackgroundColor({ color: newStyles.page });
            }
        }
    }, [darkModeEnabled]);
    return (_jsx(ThemeContext.Provider, __assign({ value: __assign({ _theme: {
                value: theme,
                dark: darkModeEnabled,
            } }, styles) }, { children: children }), void 0));
};
export default useTheme;
var templateObject_1;
