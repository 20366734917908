var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { IoClose, IoMegaphoneOutline, IoTrashOutline, IoContrastOutline, IoSunnyOutline, IoMoonOutline, IoChevronForward, IoChevronBack, IoNotificationsOutline, IoCloudDownloadOutline, IoCloudDone, IoCloudOfflineOutline, } from 'react-icons/io5';
import { FaArrowCircleUp } from 'react-icons/fa';
import { FaPlus } from 'react-icons/fa';
import { tw } from 'twind';
import { ReactComponent as CheckmarkIcon } from '../icons/checkmark.svg';
import { ReactComponent as ClockIcon } from '../icons/clock.svg';
import { ReactComponent as CollapseIcon } from '../icons/collapse.svg';
import { ReactComponent as CompassIcon } from '../icons/compass.svg';
import { ReactComponent as ExpandIcon } from '../icons/expand.svg';
import { ReactComponent as HomeIcon } from '../icons/home.svg';
import { ReactComponent as PinActiveIcon } from '../icons/pin-active.svg';
import { ReactComponent as PinIcon } from '../icons/pin.svg';
import { ReactComponent as ProfileIcon } from '../icons/profile.svg';
import { ReactComponent as SearchIcon } from '../icons/search.svg';
var Icon = function (_a) {
    var className = _a.className, RawComponent = _a.as, small = _a.small, props = __rest(_a, ["className", "as", "small"]);
    var Component = RawComponent;
    if (typeof Component === 'string') {
        switch (Component) {
            case 'home':
                Component = HomeIcon;
                break;
            case 'compass':
                Component = CompassIcon;
                break;
            case 'download':
                Component = IoCloudDownloadOutline;
                break;
            case 'downloaded':
                Component = IoCloudDone;
                break;
            case 'offline':
                Component = IoCloudOfflineOutline;
                break;
            case 'search':
                Component = SearchIcon;
                break;
            case 'profile':
                Component = ProfileIcon;
                break;
            case 'clock':
                Component = ClockIcon;
                break;
            case 'checkmark':
                Component = CheckmarkIcon;
                break;
            case 'collapse':
                Component = CollapseIcon;
                break;
            case 'expand':
                Component = ExpandIcon;
                break;
            case 'pin':
                Component = PinIcon;
                break;
            case 'pin-active':
                Component = PinActiveIcon;
                break;
            case 'close':
                Component = IoClose;
                break;
            case 'trash':
                Component = IoTrashOutline;
                break;
            case 'announcement':
                Component = IoMegaphoneOutline;
                break;
            case 'notification':
                Component = IoNotificationsOutline;
                break;
            case 'up-arrow':
                Component = FaArrowCircleUp;
                break;
            case 'plus':
                Component = FaPlus;
                break;
            case 'contrast':
                Component = IoContrastOutline;
                break;
            case 'sun':
                Component = IoSunnyOutline;
                break;
            case 'moon':
                Component = IoMoonOutline;
                break;
            case 'next':
                Component = IoChevronForward;
                break;
            case 'prev':
                Component = IoChevronBack;
                break;
        }
    }
    return (_jsx(Component, __assign({}, props, { className: tw(small ? tw(templateObject_1 || (templateObject_1 = __makeTemplateObject(["min-w-[1.5rem] w-6 h-6"], ["min-w-[1.5rem] w-6 h-6"]))) : tw(templateObject_2 || (templateObject_2 = __makeTemplateObject(["min-w-[2rem] w-8 h-8"], ["min-w-[2rem] w-8 h-8"]))), className) }), void 0));
};
export default Icon;
var templateObject_1, templateObject_2;
