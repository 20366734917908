var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { tw } from 'twind';
import { ModalHeader, ModalFooter } from './BaseModal';
import { Toasts } from '../../constants/constants-toast';
import useModal from '../../hooks/useModal';
import { Colors, Styles } from '../../constants/constants-styles';
import { Capacitor } from '@capacitor/core';
import { RadioGroup, Switch } from '@headlessui/react';
import React from 'react';
import useSettings from '../../hooks/useSettings';
import { Theme } from '../../types/theme';
import { IoClipboard, IoRefreshOutline } from 'react-icons/io5';
import Icon from '../Icon';
import toast from 'react-hot-toast';
import BaseToast from '../toasts/BaseToast';
import useTheme from '../../hooks/useTheme';
import { useParse } from '../../hooks/useParse';
import Button from '../Button';
import Section, { SectionTitle, SectionSubtitle, SectionMessage, } from '../Section';
import { useNotifications } from '../../hooks/notifications/useNotifications';
import * as Parse from 'parse';
import { Clipboard } from '@capacitor/clipboard';
import { useNetwork } from '../../hooks/useNetwork';
var SwitchSection = function (_a) {
    var title = _a.title, subtitle = _a.subtitle, message = _a.message, checked = _a.checked, onChange = _a.onChange, disabled = _a.disabled;
    var theme = useTheme();
    return (_jsxs(Switch.Group, __assign({ as: "div", className: tw("flex justify-between w-full items-center") }, { children: [_jsxs(Switch.Label, __assign({ className: tw("flex flex-col") }, { children: [_jsx(SectionTitle, __assign({ disabled: disabled }, { children: title }), void 0), subtitle && (_jsx(SectionSubtitle, __assign({ disabled: disabled }, { children: subtitle }), void 0)), message && (_jsx(SectionMessage, __assign({ disabled: disabled }, { children: message }), void 0))] }), void 0), _jsx(Switch, __assign({ disabled: disabled, checked: checked, onChange: function (c) { return onChange(c); }, className: tw(Styles.transition, "relative inline-flex items-center h-8 min-w-[3.5rem]", "rounded-full border-4", "focus:(outline-none border-[" + Colors.primary + "])", disabled && tw(templateObject_1 || (templateObject_1 = __makeTemplateObject(["cursor-not-allowed"], ["cursor-not-allowed"]))), checked
                    ? "bg-[" + theme.secondary + "] border-[" + theme.secondary + "]"
                    : "border-[" + theme.border + "]") }, { children: _jsx("span", { className: tw(Styles.transition, "inline-block w-4 h-4", "rounded-full", disabled
                        ? "bg-[" + theme.border + "]"
                        : tw(checked
                            ? "bg-[" + theme.primary + "] translate-x-7"
                            : "bg-[" + theme.secondaryText + "] translate-x-1"), checked ? "translate-x-7" : "translate-x-1") }, void 0) }), void 0)] }), void 0));
};
export var SettingsModal = function () {
    var settings = useSettings();
    var modal = useModal();
    var theme = useTheme();
    var _a = useParse(), logout = _a.logout, authenticated = _a.authenticated;
    var notifications = useNotifications();
    var network = useNetwork();
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { title: "Settings" }, void 0), _jsx(Section, __assign({ sectionTitle: "Display theme", sectionSubtitle: "Choose theme", row: "never" }, { children: _jsxs(RadioGroup, __assign({ value: settings.theme, onChange: function (theme) { return settings.update({ theme: theme }); }, className: tw("pl-4 pt-2", "flex flex-col space-y-4 py-4", "md:(flex-row space-y-0 space-x-4)") }, { children: [_jsx(RadioGroup.Label, __assign({ className: tw("sr-only") }, { children: "Theme" }), void 0), Object.entries(Theme).map(function (_a) {
                            var k = _a[0], v = _a[1];
                            return (_jsxs(RadioGroup.Option, __assign({ value: v.value, className: function (_a) {
                                    var checked = _a.checked;
                                    return tw(Styles.rounded, Styles.transition, Styles.font.inter, Styles.hover, Styles.focus, "px-4 py-2", "flex justify-between items-center space-x-4", "select-none text-left font-semibold cursor-pointer", "border-4", "rm-highlight", checked
                                        ? "bg-[" + theme.secondary + "] border-[" + theme.secondary + "] text-[" + theme.primary + "]"
                                        : "border-[" + theme.border + "] text-[" + theme.text + "]");
                                } }, { children: [_jsx(Icon, { as: v.icon, className: tw("h-full w-8") }, void 0), _jsx("span", { children: k }, void 0)] }), void 0));
                        })] }), void 0) }), void 0), Capacitor.getPlatform() !== 'web' && (_jsxs(_Fragment, { children: [_jsx(SwitchSection, { disabled: !network.connected, title: "Push Notifications", subtitle: "Enable push notifications on iOS and Android", message: !network.connected ? 'Disabled while offline' : undefined, checked: settings.allowNotifications, onChange: function (checked) { return __awaiter(void 0, void 0, void 0, function () {
                            var allowNotifications, _a, _b;
                            return __generator(this, function (_c) {
                                switch (_c.label) {
                                    case 0:
                                        _a = checked;
                                        if (!_a) return [3 /*break*/, 3];
                                        _b = notifications.hasPermission;
                                        if (_b) return [3 /*break*/, 2];
                                        return [4 /*yield*/, notifications.requestPermission()];
                                    case 1:
                                        _b = (_c.sent()) === 'granted';
                                        _c.label = 2;
                                    case 2:
                                        _a = (_b);
                                        _c.label = 3;
                                    case 3:
                                        allowNotifications = _a;
                                        settings.update({ allowNotifications: allowNotifications });
                                        if (!notifications.playerId) return [3 /*break*/, 5];
                                        return [4 /*yield*/, Parse.Cloud.run('updateNotificationPreferences', {
                                                preferences: {
                                                    playerId: notifications.playerId,
                                                    allowNotifications: allowNotifications,
                                                },
                                            })];
                                    case 4:
                                        _c.sent();
                                        _c.label = 5;
                                    case 5: return [2 /*return*/];
                                }
                            });
                        }); } }, void 0), _jsx(SwitchSection, { disabled: !network.connected, title: "New Announcement Notifications", subtitle: "Receive a push notification when new announcements are posted", message: !network.connected ? 'Disabled while offline' : undefined, checked: settings.newAnnouncements, onChange: function (checked) { return __awaiter(void 0, void 0, void 0, function () {
                            var newAnnouncements, _a, _b;
                            return __generator(this, function (_c) {
                                switch (_c.label) {
                                    case 0:
                                        _a = checked;
                                        if (!_a) return [3 /*break*/, 3];
                                        _b = notifications.hasPermission;
                                        if (_b) return [3 /*break*/, 2];
                                        return [4 /*yield*/, notifications.requestPermission()];
                                    case 1:
                                        _b = (_c.sent()) === 'granted';
                                        _c.label = 2;
                                    case 2:
                                        _a = (_b);
                                        _c.label = 3;
                                    case 3:
                                        newAnnouncements = _a;
                                        settings.update({ newAnnouncements: newAnnouncements });
                                        if (!notifications.playerId) return [3 /*break*/, 5];
                                        return [4 /*yield*/, Parse.Cloud.run('updateNotificationPreferences', {
                                                preferences: {
                                                    playerId: notifications.playerId,
                                                    newAnnouncements: newAnnouncements,
                                                },
                                            })];
                                    case 4:
                                        _c.sent();
                                        _c.label = 5;
                                    case 5: return [2 /*return*/];
                                }
                            });
                        }); } }, void 0), _jsx(SwitchSection, { disabled: !network.connected, title: "Post Update Notifications", subtitle: "Receive a push notification when posts you've downloaded are updated", message: !network.connected ? 'Disabled while offline' : undefined, checked: settings.postUpdates, onChange: function (checked) { return __awaiter(void 0, void 0, void 0, function () {
                            var postUpdates, _a, _b;
                            return __generator(this, function (_c) {
                                switch (_c.label) {
                                    case 0:
                                        _a = checked;
                                        if (!_a) return [3 /*break*/, 3];
                                        _b = notifications.hasPermission;
                                        if (_b) return [3 /*break*/, 2];
                                        return [4 /*yield*/, notifications.requestPermission()];
                                    case 1:
                                        _b = (_c.sent()) === 'granted';
                                        _c.label = 2;
                                    case 2:
                                        _a = (_b);
                                        _c.label = 3;
                                    case 3:
                                        postUpdates = _a;
                                        settings.update({ postUpdates: postUpdates });
                                        if (!notifications.playerId) return [3 /*break*/, 5];
                                        return [4 /*yield*/, Parse.Cloud.run('updateNotificationPreferences', {
                                                preferences: {
                                                    playerId: notifications.playerId,
                                                    postUpdates: postUpdates,
                                                },
                                            })];
                                    case 4:
                                        _c.sent();
                                        _c.label = 5;
                                    case 5: return [2 /*return*/];
                                }
                            });
                        }); } }, void 0), _jsx(SwitchSection, { disabled: !network.connected, title: "Testing Facility/Clinic Update Notifications", subtitle: "Receive a push notification when testing facilities and clinics you follow are updated", message: !network.connected ? 'Disabled while offline' : undefined, checked: settings.placeUpdates, onChange: function (checked) { return __awaiter(void 0, void 0, void 0, function () {
                            var placeUpdates, _a, _b;
                            return __generator(this, function (_c) {
                                switch (_c.label) {
                                    case 0:
                                        _a = checked;
                                        if (!_a) return [3 /*break*/, 3];
                                        _b = notifications.hasPermission;
                                        if (_b) return [3 /*break*/, 2];
                                        return [4 /*yield*/, notifications.requestPermission()];
                                    case 1:
                                        _b = (_c.sent()) === 'granted';
                                        _c.label = 2;
                                    case 2:
                                        _a = (_b);
                                        _c.label = 3;
                                    case 3:
                                        placeUpdates = _a;
                                        settings.update({ placeUpdates: placeUpdates });
                                        if (!notifications.playerId) return [3 /*break*/, 5];
                                        return [4 /*yield*/, Parse.Cloud.run('updateNotificationPreferences', {
                                                preferences: {
                                                    playerId: notifications.playerId,
                                                    placeUpdates: placeUpdates,
                                                },
                                            })];
                                    case 4:
                                        _c.sent();
                                        _c.label = 5;
                                    case 5: return [2 /*return*/];
                                }
                            });
                        }); } }, void 0)] }, void 0)), notifications.playerId && (_jsx(Section, __assign({ sectionTitle: "Push Notification ID", sectionSubtitle: "This is used by developers for debugging and testing", sectionMessage: notifications.playerId }, { children: _jsx(Button, __assign({ onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, Clipboard.write({
                                        string: notifications.playerId,
                                    })];
                                case 1:
                                    _a.sent();
                                    toast.custom(function (t) { return (_jsx(BaseToast, __assign({ theme: theme, iconName: IoClipboard, className: tw("text-[" + theme.primary + "] bg-[" + theme.secondary + "]") }, t, { children: _jsx("h1", { children: "Copied to clipboard!" }, void 0) }), void 0)); }, {
                                        id: Toasts.copyToClipboard,
                                    });
                                    return [2 /*return*/];
                            }
                        });
                    }); } }, { children: "Copy to Clipboard" }), void 0) }), void 0)), _jsx(Section, __assign({ disabled: !network.connected, sectionTitle: "Administrative Tools", sectionSubtitle: _jsxs(_Fragment, { children: ["For ", _jsx("strong", { children: "admins and moderators" }, void 0), " only"] }, void 0), sectionMessage: !network.connected ? 'Disabled while offline' : undefined }, { children: _jsx(Button, __assign({ disabled: !network.connected, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!!authenticated) return [3 /*break*/, 1];
                                    modal.show({ name: 'login', props: {} });
                                    return [3 /*break*/, 3];
                                case 1: return [4 /*yield*/, logout()];
                                case 2:
                                    _a.sent();
                                    _a.label = 3;
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); }, primary: !authenticated, danger: authenticated }, { children: authenticated ? 'Logout' : 'Login' }), void 0) }), void 0), authenticated && (_jsxs(_Fragment, { children: [_jsxs(Section, __assign({ sectionTitle: "Resources", sectionSubtitle: "Manage in-app content" }, { children: [_jsx(Button, __assign({ onClick: function () { return modal.show({ name: 'changePost', props: {} }); }, primary: true }, { children: "Create Post" }), void 0), _jsx(Button, __assign({ onClick: function () {
                                    return modal.show({ name: 'changeAnnouncement', props: {} });
                                }, primary: true }, { children: "Create Announcement" }), void 0)] }), void 0), _jsxs(Section, __assign({ sectionTitle: "Notifications", sectionSubtitle: "Manage push notifications" }, { children: [_jsx(Button, __assign({ disabled: true, onClick: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                    return [2 /*return*/, modal.show({ name: 'send-notification', props: {} })];
                                }); }); }, primary: true }, { children: "Send Push Notification" }), void 0), _jsx(Button, __assign({ onClick: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
                                    return [2 /*return*/, modal.show({ name: 'view-notifications', props: {} })];
                                }); }); } }, { children: "View all notifications" }), void 0)] }), void 0), _jsx(Section, __assign({ sectionTitle: "Developer Tools" }, { children: _jsx(Button, __assign({ onClick: function () {
                                return toast.custom(function (t) { return (_jsx(BaseToast, __assign({ theme: theme }, t, { children: _jsxs("div", __assign({ className: "flex flex-col" }, { children: [_jsx("h1", { children: "asdf" }, void 0), _jsx("p", { children: "asdf" }, void 0)] }), void 0) }), void 0)); }, {
                                    id: 'sample',
                                });
                            }, primary: true }, { children: "Spawn Sample Notification" }), void 0) }), void 0)] }, void 0)), _jsxs(ModalFooter, { children: [_jsx(Button, __assign({ danger: true, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                modal.show({
                                    name: 'confirm',
                                    props: {
                                        headerOptions: {
                                            title: 'Reset Settings',
                                            subtitle: authenticated
                                                ? 'This will sign out of your account'
                                                : undefined,
                                        },
                                        onConfirm: function () { return __awaiter(void 0, void 0, void 0, function () {
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0: return [4 /*yield*/, logout()];
                                                    case 1:
                                                        _a.sent();
                                                        settings.useDefaults();
                                                        modal.hide();
                                                        toast.custom(function (t) { return (_jsx(BaseToast, __assign({}, t, { theme: theme, iconName: IoRefreshOutline, className: tw("text-[" + theme.dangerText + "] bg-[" + theme.danger + "]"), iconClassName: tw("text-[" + theme.dangerText + "]") }, { children: _jsx("h1", { children: "Reset settings to default!" }, void 0) }), void 0)); }, {
                                                            id: Toasts.resetSettings,
                                                        });
                                                        return [2 /*return*/];
                                                }
                                            });
                                        }); },
                                        onCancel: function () { return modal.show({ name: 'settings', props: {} }); },
                                    },
                                });
                                return [2 /*return*/];
                            });
                        }); } }, { children: "Reset Settings" }), void 0), _jsx(Button, __assign({ onClick: function () {
                            settings.useSaved();
                            modal.hide();
                        } }, { children: "Cancel" }), void 0)] }, void 0)] }, void 0));
};
export default SettingsModal;
var templateObject_1;
