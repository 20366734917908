var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { tw } from 'twind';
import { BottomNav } from './BottomNav';
import DebugPanel from './DebugPanel';
import Nav from './Nav';
var Page = function (_a) {
    var _b = _a.nav, nav = _b === void 0 ? true : _b, children = _a.children, className = _a.className;
    return (_jsxs(_Fragment, { children: [nav && _jsx(Nav, {}, void 0), _jsx("main", __assign({ className: tw("px-1 w-full lg:(pt-36 px-12)", className) }, { children: children }), void 0), _jsx(BottomNav, {}, void 0), process.env.NODE_ENV === 'development' && _jsx(DebugPanel, {}, void 0)] }, void 0));
};
export default Page;
