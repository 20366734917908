var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useRef } from 'react';
import { tw } from 'twind';
import { Styles } from '../constants/constants-styles';
import Icon from './Icon';
import useTheme from '../hooks/useTheme';
import Button from './Button';
export var SearchAction = function (_a) {
    var _b;
    var className = _a.className, text = _a.text, icon = _a.icon, query = _a.query, kind = _a.kind, setQuery = _a.setQuery, props = __rest(_a, ["className", "text", "icon", "query", "kind", "setQuery"]);
    var theme = useTheme();
    return (_jsxs(Button, __assign({ title: (_b = props.title) !== null && _b !== void 0 ? _b : text, primary: kind === 'primary', danger: kind === 'danger', className: tw("flex justify-between items-center space-x-2", className) }, props, { children: [icon && _jsx(Icon, { small: true, as: icon, className: tw("inline") }, void 0), _jsx("p", { children: text }, void 0)] }), void 0));
};
var Search = function (_a) {
    var className = _a.className, actions = _a.actions, query = _a.query, setQuery = _a.setQuery, props = __rest(_a, ["className", "actions", "query", "setQuery"]);
    var inputRef = useRef(null);
    var theme = useTheme();
    var focusTextBox = useCallback(function () { var _a; return (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus(); }, [inputRef]);
    return (_jsxs("div", __assign({ className: tw("hidden lg:(grid grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-x-4)") }, { children: [_jsxs("div", __assign({ onClick: focusTextBox, className: tw(Styles.rounded, Styles.font.body, Styles.transition, "flex justify-center items-center space-x-4", "p-4 text-left cursor-text", "border-4 border-[" + theme.text + "]", "focus-within:(border-[" + theme.primary + "])", (!actions || actions.length < 1) && tw(templateObject_1 || (templateObject_1 = __makeTemplateObject(["col-span-full"], ["col-span-full"]))), actions &&
                    actions.length > 0 && tw(templateObject_2 || (templateObject_2 = __makeTemplateObject(["col-span-2 xl:col-span-3 2xl:col-span-4"], ["col-span-2 xl:col-span-3 2xl:col-span-4"]))), query && query.length > 0
                    ? "text-[" + theme.primary + "] bg-[" + theme.secondary + "] border-opacity-0"
                    : "text-[" + theme.secondaryText + "] bg-[" + theme.border + "] border-opacity-5") }, { children: [_jsx(Icon, { as: "search", type: "outline", style: { minHeight: 32, minWidth: 32 } }, void 0), _jsx("input", __assign({ ref: inputRef, placeholder: "Search", onChange: function (e) { return setQuery === null || setQuery === void 0 ? void 0 : setQuery(e.target.value); }, className: tw("min-w-[60pt] w-[" + (query === null || query === void 0 ? void 0 : query.length) + "ch] text-lg bg-transparent font-bold focus:outline-none placeholder-[" + theme.secondaryText + "]") }, props), void 0)] }), void 0), actions &&
                actions.length > 0 &&
                actions.map(function (props) { return (_jsx(SearchAction, __assign({}, props), props.id + props.text)); })] }), void 0));
};
export default Search;
var templateObject_1, templateObject_2;
