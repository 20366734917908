var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import { PushNotifications, } from '@capacitor/push-notifications';
import useParse from '../useParse';
import * as Parse from 'parse';
var NotificationsContext = React.createContext({
    all: [],
    permissionState: 'denied',
    hasPermission: false,
    initialized: false,
    initialize: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/];
    }); }); },
    requestPermission: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, 'denied'];
    }); }); },
    setPlayerId: function () { },
});
export var useNotifications = function () { return React.useContext(NotificationsContext); };
export var NotificationsProvider = function (_a) {
    var children = _a.children;
    var parse = useParse();
    var _b = useState([]), notifications = _b[0], setNotifications = _b[1];
    var _c = useState('denied'), permissionState = _c[0], setPermissionState = _c[1];
    var _d = useState(), playerId = _d[0], setPlayerId = _d[1];
    var _e = useState(false), initialized = _e[0], setInitialized = _e[1];
    useEffect(function () {
        if (parse.initialized && parse.connected && playerId) {
            Parse.Cloud.run('getNotificationHistory', { playerId: playerId }).then(function (results) {
                return setNotifications(results);
            });
        }
    }, [parse.initialized, parse.connected, playerId]);
    var initialize = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            // TODO: Update when browser notifications are implemented
            if (Capacitor.getPlatform() !== 'web') {
                PushNotifications.checkPermissions().then(function (_a) {
                    var receive = _a.receive;
                    return setPermissionState(receive);
                });
            }
            setInitialized(true);
            return [2 /*return*/];
        });
    }); };
    var requestPermission = function () { return __awaiter(void 0, void 0, Promise, function () {
        var updatedPermissionState;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, PushNotifications.requestPermissions()];
                case 1:
                    updatedPermissionState = (_a.sent()).receive;
                    setPermissionState(updatedPermissionState);
                    return [2 /*return*/, updatedPermissionState];
            }
        });
    }); };
    return (_jsx(NotificationsContext.Provider, __assign({ value: {
            all: notifications,
            permissionState: permissionState,
            hasPermission: permissionState === 'granted',
            playerId: playerId,
            initialized: initialized,
            initialize: initialize,
            requestPermission: requestPermission,
            setPlayerId: setPlayerId,
        } }, { children: children }), void 0));
};
