var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { tw, css } from 'twind/css';
import { ModalHeader, ModalFooter } from './BaseModal';
import useModal from '../../hooks/useModal';
import useAnnouncements from '../../hooks/useAnnouncements';
import { Styles } from '../../constants/constants-styles';
import useTheme from '../../hooks/useTheme';
import Button from '../Button';
import { useState } from 'react';
import Fuse from 'fuse.js';
export var AnnouncementListTile = function (_a) {
    var _b, _c;
    var announcement = _a.announcement, className = _a.className, props = __rest(_a, ["announcement", "className"]);
    var theme = useTheme();
    return (_jsxs("button", __assign({ className: tw("w-full text-left focus:outline-none", "flex space-x-4 items-center", className) }, props, { children: [_jsx("img", { src: (_c = (_b = announcement.attributes.image) === null || _b === void 0 ? void 0 : _b.url()) !== null && _c !== void 0 ? _c : '', alt: announcement.attributes.title, className: tw(Styles.rounded, "h-16 w-16", css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n            background-size: cover;\n            background-postion: center;\n          "], ["\n            background-size: cover;\n            background-postion: center;\n          "])))) }, void 0), _jsxs("div", __assign({ className: tw("text-[" + theme.text + "]") }, { children: [_jsx("h2", __assign({ className: tw("text-2xl") }, { children: announcement.attributes.title }), void 0), _jsx("h3", { children: announcement.attributes.subtitle }, void 0)] }), void 0)] }), void 0));
};
export var AnnouncementListModal = function () {
    var announcements = useAnnouncements().announcements;
    var modal = useModal();
    var theme = useTheme();
    var _a = useState(''), query = _a[0], setQuery = _a[1];
    var searchAnnouncements = new Fuse(announcements, {
        keys: ['attributes.title', 'attributes.description'],
        includeScore: true,
    });
    var searchResults = searchAnnouncements
        .search(query)
        .map(function (_a) {
        var item = _a.item;
        return item;
    });
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { title: !announcements || announcements.length < 1
                    ? 'No Announcements'
                    : 'Announcements' }, void 0), _jsx("input", { placeholder: "Search", onChange: function (e) { return setQuery(e.target.value); }, className: tw(Styles.rounded, Styles.transition, query && query.length > 0
                    ? "text-[" + theme.primary + "] bg-[" + theme.secondary + "] border-opacity-0"
                    : "text-[" + theme.secondaryText + "] bg-[" + theme.border + "] border-opacity-5", "w-1/2 h-16 pl-2 min-w-[60pt] text-lg font-bold border-4 border-[" + theme.text + "] focus-within:(border-[" + theme.primary + "]) focus:outline-none placeholder-[" + theme.secondaryText + "] ") }, void 0), _jsx("section", __assign({ className: tw("flex flex-col space-y-4") }, { children: announcements && !query && announcements.length > 0
                    ? announcements.map(function (a, i) { return (_jsx(AnnouncementListTile, { announcement: a, onClick: function () {
                            return modal.show({
                                name: 'announcement',
                                props: { announcement: a, closeToList: true },
                            });
                        } }, i + a.attributes.title + new Date().getUTCMilliseconds())); })
                    : searchResults.map(function (a, i) { return (_jsx(AnnouncementListTile, { announcement: a, onClick: function () {
                            return modal.show({
                                name: 'announcement',
                                props: { announcement: a, closeToList: true },
                            });
                        } }, i + a.attributes.title + new Date().getUTCMilliseconds())); }) }), void 0), _jsx(ModalFooter, { children: _jsx(Button, __assign({ onClick: function () { return modal.hide(); } }, { children: "Close" }), void 0) }, void 0)] }, void 0));
};
export default AnnouncementListModal;
var templateObject_1;
