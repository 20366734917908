var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { tw } from 'twind';
import { ModalHeader, ModalFooter } from './BaseModal';
import Icon from '../Icon';
import { Colors, Styles } from '../../constants/constants-styles';
import useModal from '../../hooks/useModal';
import { useDownloads } from '../../hooks/useDownloads';
import { IoBuild } from 'react-icons/io5';
import useTheme from '../../hooks/useTheme';
import useParse from '../../hooks/useParse';
import Button from '../Button';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useNetwork } from '../../hooks/useNetwork';
import { useEffect, useState } from 'react';
export var PostModal = function (_a) {
    var _b;
    var postQuery = _a.post;
    var network = useNetwork();
    var _c = useDownloads(), download = _c.download, isDownloaded = _c.isDownloaded;
    var modal = useModal();
    var theme = useTheme();
    var _d = useParse(), authenticated = _d.authenticated, posts = _d.posts;
    var _e = useState(), post = _e[0], setPost = _e[1];
    useEffect(function () {
        if (typeof postQuery === 'string') {
            setPost(posts.find(function (_a) {
                var id = _a.id;
                return id === postQuery;
            }));
        }
        else {
            setPost(postQuery);
        }
    }, [postQuery]);
    return (_jsxs(_Fragment, { children: [!post && _jsx(ModalHeader, { title: "Loading Post..." }, void 0), post && (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { title: post.attributes.title, subtitle: (function () {
                            var createdAt = post.createdAt, updatedAt = post.updatedAt;
                            dayjs.extend(relativeTime);
                            var subtitle = [
                                "created " + dayjs(createdAt.toString()).from(new Date()),
                            ];
                            if (!dayjs(createdAt).isSame(updatedAt, 'day')) {
                                subtitle.push("updated " + dayjs(updatedAt.toString()).from(new Date()));
                            }
                            return subtitle;
                        })(), actions: [
                            {
                                hidden: !network.connected,
                                icon: isDownloaded(post.id) ? 'downloaded' : 'download',
                                className: tw(isDownloaded(post.id) && "text-[" + theme.primary + "]", !isDownloaded(post.id) && "text-[" + theme.text + "]"),
                                onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, download(post)];
                                            case 1:
                                                _a.sent();
                                                return [2 /*return*/];
                                        }
                                    });
                                }); },
                            },
                            {
                                hidden: !network.connected,
                                icon: IoBuild,
                                className: tw(authenticated ? "" : "hidden"),
                                onClick: function () {
                                    modal.show({
                                        name: 'changePost',
                                        props: {
                                            title: post.attributes.title,
                                            description: post.attributes.description,
                                            links: post.attributes.links,
                                            post: post,
                                        },
                                    });
                                },
                            },
                        ] }, void 0), _jsxs("div", __assign({ className: tw("flex flex-col space-y-8") }, { children: [_jsx("span", __assign({ className: tw("text-[" + theme.text + "]") }, { children: (_b = post.attributes.description) !== null && _b !== void 0 ? _b : 'No Description' }), void 0), _jsx("div", __assign({ className: tw("flex flex-col space-y-4") }, { children: post.attributes.links && (_jsxs("div", __assign({ className: tw("flex flex-col") }, { children: [_jsx("h2", __assign({ className: tw(Styles.font.body, "text-xl font-bold text-[" + theme.text + "]") }, { children: "Links" }), void 0), _jsx("div", __assign({ className: tw("flex flex-col w-3/4") }, { children: post.attributes.links.map(function (link) { return (_jsx("a", __assign({ href: link, className: tw(Styles.font.inter, "text-[" + Colors.primary + "] text-underline") }, { children: link }), void 0)); }) }), void 0)] }), void 0)) }), void 0)] }), void 0)] }, void 0)), _jsxs(ModalFooter, { children: [post && (_jsxs(Button, __assign({ disabled: !network.connected, className: tw("flex space-x-2"), primary: !isDownloaded(post.id), danger: isDownloaded(post.id), onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, download(post)];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); } }, { children: [_jsx(Icon, { small: true, as: isDownloaded(post.id) ? 'offline' : 'download', className: tw("inline") }, void 0), _jsxs("p", { children: [isDownloaded(post.id) ? 'Remove from' : 'Add to', " Downloads"] }, void 0)] }), void 0)), _jsx(Button, __assign({ onClick: function () { return modal.hide(); } }, { children: "Close" }), void 0)] }, void 0)] }, void 0));
};
export default PostModal;
