import { useCallback, useEffect, useState } from 'react';
export default function useScrollHide() {
    var _a = useState(window.scrollY), y = _a[0], setY = _a[1];
    var _b = useState(true), visible = _b[0], setVisible = _b[1];
    var bottom = Math.ceil(window.innerHeight + window.scrollY) >=
        document.documentElement.scrollHeight;
    var handleNavigation = useCallback(function (e) {
        var window = e.currentTarget;
        setVisible(y >= window.scrollY);
        setY(window.scrollY);
    }, [y]);
    useEffect(function () {
        setY(window.scrollY);
        window.addEventListener('scroll', handleNavigation);
        return function () {
            window.removeEventListener('scroll', handleNavigation);
        };
    }, [handleNavigation]);
    return { y: y, bottom: bottom, visible: visible };
}
