var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { tw } from 'twind';
import { Colors, Styles } from '../constants/constants-styles';
import useTheme from '../hooks/useTheme';
import Icon from './Icon';
var NavItem = function (_a) {
    var isTitle = _a.isTitle, children = _a.children, className = _a.className, icon = _a.icon, active = _a.active, props = __rest(_a, ["isTitle", "children", "className", "icon", "active"]);
    var theme = useTheme();
    return (_jsxs(Link, __assign({ className: tw(Styles.font.title, Styles.rounded, "flex items-center", "text-xs sm:text-sm lg:text-lg 2xl:text-2xl", active && tw(templateObject_1 || (templateObject_1 = __makeTemplateObject(["text-[", "] font-bold"], ["text-[", "] font-bold"])), Colors.primary), !active && tw(templateObject_2 || (templateObject_2 = __makeTemplateObject(["text-[", "] font-semibold"], ["text-[", "] font-semibold"])), theme.text), !isTitle && tw(templateObject_3 || (templateObject_3 = __makeTemplateObject(["px-4 py-2 text-sm space-y-1 lg:space-y-0 xl:space-x-4"], ["px-4 py-2 text-sm space-y-1 lg:space-y-0 xl:space-x-4"]))), className) }, props, { children: [icon && _jsx(Icon, { as: icon }, void 0), isTitle && children, !isTitle && _jsx("p", { children: children }, void 0)] }), void 0));
};
export var NavModalItem = function (_a) {
    var children = _a.children, className = _a.className, icon = _a.icon, active = _a.active, props = __rest(_a, ["children", "className", "icon", "active"]);
    var theme = useTheme();
    return (_jsxs("button", __assign({ className: tw(Styles.font.title, Styles.rounded, "flex items-center px-4 py-2 text-sm focus:outline-none", "text-xs sm:text-sm space-y-1 lg:space-y-0 xl:(space-x-4 text-lg)", active && tw(templateObject_4 || (templateObject_4 = __makeTemplateObject(["text-[", "] font-bold"], ["text-[", "] font-bold"])), Colors.primary), !active && tw(templateObject_5 || (templateObject_5 = __makeTemplateObject(["text-[", "] font-semibold"], ["text-[", "] font-semibold"])), theme.text), props.disabled && tw(templateObject_6 || (templateObject_6 = __makeTemplateObject(["cursor-not-allowed opacity-25"], ["cursor-not-allowed opacity-25"]))), className) }, props, { children: [icon && _jsx(Icon, { as: icon }, void 0), _jsx("p", { children: children }, void 0)] }), void 0));
};
export default NavItem;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
