var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { tw } from 'twind';
import { ModalHeader, ModalFooter } from './BaseModal';
import Icon from '../Icon';
import useModal from '../../hooks/useModal';
import useTheme from '../../hooks/useTheme';
import Button from '../Button';
export var ConfirmModal = function (_a) {
    var _b;
    var headerOptions = _a.headerOptions, icon = _a.icon, onConfirm = _a.onConfirm, onCancel = _a.onCancel;
    var modal = useModal();
    var theme = useTheme();
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, __assign({ title: (_b = headerOptions === null || headerOptions === void 0 ? void 0 : headerOptions.title) !== null && _b !== void 0 ? _b : 'Are you sure?' }, headerOptions), void 0), _jsx("div", __assign({ className: tw("flex flex-col space-y-8 text-[" + theme.text + "]") }, { children: _jsxs("p", { children: ["This action ", _jsx("b", { children: "cannot" }, void 0), " be undone."] }, void 0) }), void 0), _jsxs(ModalFooter, { children: [_jsxs(Button, __assign({ danger: true, className: tw("flex space-x-2"), onClick: function () {
                            if (!onConfirm) {
                                modal.hide();
                            }
                            onConfirm === null || onConfirm === void 0 ? void 0 : onConfirm();
                        } }, { children: [icon && _jsx(Icon, { small: true, as: icon, className: tw("inline") }, void 0), _jsx("p", { children: "Confirm" }, void 0)] }), void 0), _jsx(Button, __assign({ onClick: function () {
                            if (!onCancel) {
                                modal.hide();
                            }
                            onCancel === null || onCancel === void 0 ? void 0 : onCancel();
                        } }, { children: "Cancel" }), void 0)] }, void 0)] }, void 0));
};
export default ConfirmModal;
