var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { tw } from 'twind';
import React, { useState } from 'react';
import Icon from './Icon';
import { Styles } from '../constants/constants-styles';
import { Transition } from '@headlessui/react';
import useTheme from '../hooks/useTheme';
import useScrollHide from '../hooks/useScrollHide';
export var ScrollTopButton = function (_a) {
    var disabled = _a.disabled, className = _a.className, props = __rest(_a, ["disabled", "className"]);
    var _b = useState(false), visible = _b[0], setVisible = _b[1];
    var theme = useTheme();
    var scrollHide = useScrollHide();
    var toggleVisible = function () {
        var scrolled = document.documentElement.scrollTop;
        setVisible(scrolled > 200);
    };
    var scrollToTop = function () {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    window.addEventListener('scroll', toggleVisible);
    return (_jsx(Transition, __assign({ appear: true, show: visible, as: "button", enter: tw(Styles.duration, "ease-out"), enterFrom: "opacity-0", enterTo: "opacity-100", leave: tw(Styles.duration, "ease-in"), leaveFrom: "opacity-100", leaveTo: "opacity-0", onClick: scrollToTop, disabled: disabled || !visible, className: tw(Styles.transition, "bg-[" + theme.page + "] text-[" + theme.text + "] p-4", "flex flex-col items-center space-y-2", "shadow-md rounded-full hover:shadow-lg active:shadow-inner focus:outline-none", scrollHide.visible ? "translate-y-0" : "translate-y-24", "lg:translate-y-0", className) }, props, { children: _jsx(Icon, { as: "up-arrow", className: tw("inline") }, void 0) }), void 0));
};
