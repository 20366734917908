var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useState } from 'react';
import { tw } from 'twind';
import { toast } from 'react-hot-toast';
import { parseOSNotification, } from '@indigenousfriends/indigihealth-shared';
import { Capacitor } from '@capacitor/core';
import { OneSignal } from '@ionic-native/onesignal';
import { PushNotifications } from '@capacitor/push-notifications';
import useSettings from '../useSettings';
import useModal from '../useModal';
import useTheme from '../useTheme';
import BaseToast from '../../components/toasts/BaseToast';
import { useNotifications } from './useNotifications';
var OneSignalContext = React.createContext({
    initialized: false,
    initialize: function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, ''];
    }); }); },
});
export var useOneSignal = function () { return React.useContext(OneSignalContext); };
export var OneSignalProvider = function (_a) {
    var children = _a.children;
    var modal = useModal();
    var settings = useSettings();
    var theme = useTheme();
    var notifications = useNotifications();
    var _b = useState(false), initialized = _b[0], setInitialized = _b[1];
    var handleNotificationClicked = function (_a) {
        var action = _a.action, _b = _a.data, data = _b === void 0 ? {} : _b;
        switch (action) {
            case 'showAnnouncement':
                var announcementId = data.announcementId;
                if (announcementId) {
                    modal.show({
                        name: 'announcement',
                        props: {
                            announcement: announcementId,
                        },
                    });
                }
                break;
            case 'showPost':
                var postId = data.postId;
                if (postId) {
                    modal.show({
                        name: 'post',
                        props: {
                            post: postId,
                        },
                    });
                }
                break;
            case 'showPlace':
                var placeId = data.placeId;
                if (placeId) {
                    // TODO: complete place notification click handler
                }
                break;
        }
    };
    var handleNotificationReceived = function (osNotification) {
        var _a;
        var notification = parseOSNotification(osNotification);
        toast.custom(function (t) { return (_jsx(BaseToast, __assign({}, t, { theme: theme, onClick: function () {
                if (notification.payload) {
                    handleNotificationClicked(notification.payload);
                }
                toast.dismiss(t.id);
            } }, { children: _jsxs("div", __assign({ className: tw(templateObject_1 || (templateObject_1 = __makeTemplateObject(["flex flex-col"], ["flex flex-col"]))) }, { children: [notification.title && (_jsx("h1", __assign({ className: tw(templateObject_2 || (templateObject_2 = __makeTemplateObject(["text-lg font-bold"], ["text-lg font-bold"]))) }, { children: notification.title }), void 0)), notification.message && _jsx("p", { children: notification.message }, void 0)] }), void 0) }), void 0)); }, {
            id: ((_a = notification.payload) === null || _a === void 0 ? void 0 : _a.action)
                ? notification.payload.action +
                    JSON.stringify(notification.payload.data)
                : undefined,
        });
    };
    var initialize = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var osAppId, playerId, receive;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    console.log('Initializing OneSignal push notifications');
                    osAppId = process.env.REACT_APP_ONESIGNAL_APP_ID;
                    if (!osAppId) {
                        throw new Error("Can't initialize push notifications: missing OneSignal app id");
                    }
                    playerId = '';
                    if (!(Capacitor.getPlatform() === 'web')) return [3 /*break*/, 1];
                    // TODO: Implement web push notifications
                    throw 'TODO: Implement web push notifications';
                case 1:
                    OneSignal.startInit(osAppId);
                    OneSignal.inFocusDisplaying(OneSignal.OSInFocusDisplayOption.None);
                    OneSignal.handleNotificationReceived().subscribe(handleNotificationReceived);
                    OneSignal.endInit();
                    return [4 /*yield*/, OneSignal.getIds()];
                case 2:
                    playerId = (_a.sent()).userId;
                    notifications.setPlayerId(playerId);
                    _a.label = 3;
                case 3: return [4 /*yield*/, PushNotifications.checkPermissions()];
                case 4:
                    receive = (_a.sent()).receive;
                    if (receive === 'granted') {
                        settings.update({ allowNotifications: true });
                    }
                    console.log('OneSignal push notifications initialized successfully');
                    setInitialized(true);
                    return [2 /*return*/, playerId];
            }
        });
    }); }, [settings]);
    return (_jsx(OneSignalContext.Provider, __assign({ value: {
            initialized: initialized,
            initialize: initialize,
        } }, { children: children }), void 0));
};
var templateObject_1, templateObject_2;
