var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { tw } from 'twind';
import Page from '../components/Page';
import PostSection from '../components/PostSection';
import Search from '../components/Search';
import TopicSection from '../components/TopicSection';
import { Colors, Styles } from '../constants/constants-styles';
import { useDownloads } from '../hooks/useDownloads';
import useModal from '../hooks/useModal';
import { toast } from 'react-hot-toast';
import Fuse from 'fuse.js';
import { useEffect, useState } from 'react';
import { ScrollTopButton } from '../components/ScrollTopButton';
import BaseToast from '../components/toasts/BaseToast';
import { useParse } from '../hooks/useParse';
import useTheme from '../hooks/useTheme';
import Nav, { NavContainer } from '../components/Nav';
import { Toasts } from '../constants/constants-toast';
import Icon from '../components/Icon';
var DownloadsPage = function () {
    var topics = useParse().topics;
    var _a = useDownloads(), downloads = _a.downloads, clearDownloads = _a.clearDownloads;
    var _b = useState(''), query = _b[0], setQuery = _b[1];
    var _c = useState([]), filtered = _c[0], setFiltered = _c[1];
    var _d = useState([]), terms = _d[0], setTerms = _d[1];
    var modal = useModal();
    var theme = useTheme();
    var fuse = new Fuse(downloads, {
        keys: ['attributes.title', 'attributes.description'],
        includeScore: true,
    });
    useEffect(function () {
        combinedSearch();
    }, [query, terms]);
    var combinedSearch = function () {
        var search = fuse.search(query).map(function (_a) {
            var item = _a.item;
            return item;
        });
        var result = terms.length > 0 && query
            ? search.filter(function (post) {
                return terms.some(function (term) {
                    //@ts-ignore
                    return post.attributes.topic.attributes.title.includes(term);
                });
            })
            : terms.length > 0
                ? downloads.filter(function (post) {
                    return terms.some(function (term) {
                        //@ts-ignore
                        return post.attributes.topic.attributes.title.includes(term);
                    });
                })
                : search;
        setFiltered(result);
    };
    return (_jsxs(Page, __assign({ nav: false, className: tw(templateObject_1 || (templateObject_1 = __makeTemplateObject(["pt-20 flex flex-col lg:(flex-row justify-end)"], ["pt-20 flex flex-col lg:(flex-row justify-end)"]))) }, { children: [_jsxs(NavContainer, { children: [_jsx(Nav, {}, void 0), _jsx("section", __assign({ className: tw(templateObject_2 || (templateObject_2 = __makeTemplateObject(["bg-[", "] fixed left-0 px-2 w-full overflow-scroll hide-scroll lg:(pb-36 fixed left-12 w-1/3 h-full) z-10"], ["bg-[", "] fixed left-0 px-2 w-full overflow-scroll hide-scroll lg:(pb-36 fixed left-12 w-1/3 h-full) z-10"])), theme.page) }, { children: _jsx(TopicSection, { topics: topics, setTerms: setTerms }, void 0) }), void 0)] }, void 0), _jsxs("section", __assign({ className: tw(templateObject_3 || (templateObject_3 = __makeTemplateObject(["flex flex-col w-full mt-24 lg:(mt-0 px-1 w-7/12 space-y-8)"], ["flex flex-col w-full mt-24 lg:(mt-0 px-1 w-7/12 space-y-8)"]))) }, { children: [_jsx(Search, { query: query, setQuery: setQuery, actions: [
                            {
                                text: 'Delete Downloads',
                                kind: 'danger',
                                icon: 'trash',
                                disabled: downloads.length < 1,
                                onClick: function () {
                                    return modal.show({
                                        name: 'confirm',
                                        props: {
                                            headerOptions: {
                                                title: 'Delete all downloaded posts?',
                                            },
                                            onConfirm: function () {
                                                modal.hide();
                                                clearDownloads();
                                                toast.custom(function (t) { return (_jsx(BaseToast, __assign({}, t, { theme: theme, iconName: "offline", className: tw("text-[" + theme.dangerText + "] bg-[" + theme.danger + "]"), iconClassName: tw("text-[" + theme.dangerText + "]") }, { children: _jsx("h1", { children: "Deleted Downloaded Posts!" }, void 0) }), void 0)); }, {
                                                    id: Toasts.clearDownloads,
                                                });
                                            },
                                            icon: 'offline',
                                        },
                                    });
                                },
                            },
                        ] }, void 0), _jsx(PostSection, { posts: query || terms.length > 0 ? filtered : downloads, loading: false }, void 0), downloads.length === 0 && (_jsxs("div", __assign({ className: tw(templateObject_4 || (templateObject_4 = __makeTemplateObject(["flex flex-col items-center justify-center space-y-8"], ["flex flex-col items-center justify-center space-y-8"]))) }, { children: [_jsxs("div", __assign({ className: "flex flex-col items-center justify-center text-center" }, { children: [_jsx("p", __assign({ className: tw(templateObject_5 || (templateObject_5 = __makeTemplateObject(["text-lg text-gray-500"], ["text-lg text-gray-500"]))) }, { children: "You have no downloads." }), void 0), _jsxs("p", __assign({ className: tw(templateObject_6 || (templateObject_6 = __makeTemplateObject(["text-lg text-gray-500"], ["text-lg text-gray-500"]))) }, { children: ["You can download posts to read offline by clicking the", ' ', _jsx(Icon, { as: "download", className: tw("inline"), small: true }, void 0), " icon on a post!"] }), void 0)] }), void 0), _jsx(Link, __assign({ to: "/" }, { children: _jsx("button", __assign({ className: tw(Styles.rounded, Styles.font.inter, "px-4 py-2 focus:outline-none", "text-[" + Colors.light + "] bg-[" + Colors.primary + "]") }, { children: "Go to Home Page" }), void 0) }), void 0)] }), void 0)), (terms.length > 0 || query) &&
                        filtered.length === 0 &&
                        downloads.length > 0 && (_jsx("div", __assign({ className: tw(templateObject_7 || (templateObject_7 = __makeTemplateObject(["flex flex-col items-center justify-center space-y-8"], ["flex flex-col items-center justify-center space-y-8"]))) }, { children: _jsx("div", __assign({ className: "flex flex-col items-center justify-center" }, { children: _jsx("div", __assign({ className: tw(templateObject_8 || (templateObject_8 = __makeTemplateObject(["text-lg text-gray-500"], ["text-lg text-gray-500"]))) }, { children: "There are no posts that match the filters you have specified" }), void 0) }), void 0) }), void 0))] }), void 0), _jsx(ScrollTopButton, { className: tw(templateObject_9 || (templateObject_9 = __makeTemplateObject(["fixed right-0 bottom-28 lg:bottom-5 right-5"], ["fixed right-0 bottom-28 lg:bottom-5 right-5"]))) }, void 0)] }), void 0));
};
export default DownloadsPage;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
