var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { tw } from 'twind';
import { ModalHeader, ModalFooter } from './BaseModal';
import useModal from '../../hooks/useModal';
import { Colors, Styles } from '../../constants/constants-styles';
import dayjs from 'dayjs';
import useTheme from '../../hooks/useTheme';
import Button from '../Button';
export var PlaceModal = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    var place = _a.place;
    var modal = useModal();
    var theme = useTheme();
    return (_jsxs(_Fragment, { children: [_jsx(ModalHeader, { title: place.attributes.name, subtitle: place.attributes.address.streetAddress }, void 0), _jsxs("div", __assign({ className: tw("flex flex-col space-y-8") }, { children: [place.attributes.requiresAppointment && (_jsx("div", { children: _jsx("span", __assign({ className: tw(Styles.rounded, "bg-[" + Colors.danger + "] px-4 py-2 text-white") }, { children: "Requires Appointment" }), void 0) }, void 0)), (((_c = (_b = place.attributes) === null || _b === void 0 ? void 0 : _b.description) === null || _c === void 0 ? void 0 : _c.en) ||
                        ((_e = (_d = place.attributes) === null || _d === void 0 ? void 0 : _d.description) === null || _e === void 0 ? void 0 : _e.fr)) && (_jsxs("div", __assign({ className: tw("flex flex-col space-y-2 text-[" + theme.text + "]") }, { children: [((_g = (_f = place.attributes) === null || _f === void 0 ? void 0 : _f.description) === null || _g === void 0 ? void 0 : _g.en) && (_jsx("span", { children: (_j = (_h = place.attributes) === null || _h === void 0 ? void 0 : _h.description) === null || _j === void 0 ? void 0 : _j.en }, void 0)), ((_l = (_k = place.attributes) === null || _k === void 0 ? void 0 : _k.description) === null || _l === void 0 ? void 0 : _l.fr) && (_jsx("span", { children: (_o = (_m = place.attributes) === null || _m === void 0 ? void 0 : _m.description) === null || _o === void 0 ? void 0 : _o.fr }, void 0))] }), void 0)), place.attributes.phoneNumbers &&
                        place.attributes.phoneNumbers.length > 0 && (_jsxs("div", __assign({ className: tw("flex flex-col") }, { children: [_jsx("h2", __assign({ className: tw(Styles.font.body, "text-xl font-bold text-[" + theme.text + "]") }, { children: "Phone Numbers" }), void 0), _jsx("div", __assign({ className: tw("flex flex-col w-3/4") }, { children: place.attributes.phoneNumbers.map(function (phone) { return (_jsx("a", __assign({ href: "tel:" + phone.number, className: tw(Styles.font.inter, "text-[" + Colors.primary + "] text-underline") }, { children: "" + phone.number + (phone.extension ? " ext. " + phone.extension : '') }), void 0)); }) }), void 0)] }), void 0)), place.attributes.openingHours && (_jsxs("div", __assign({ className: tw("flex flex-col") }, { children: [_jsx("h2", __assign({ className: tw(Styles.font.body, "text-xl font-bold text-[" + theme.text + "]") }, { children: "Hours of Operation" }), void 0), _jsx("div", __assign({ className: tw("flex flex-col space-y-2 w-3/4") }, { children: Object.entries(place.attributes.openingHours).map(function (_a) {
                                    var day = _a[0], hours = _a[1];
                                    return (_jsx("div", __assign({ className: tw("flex flex-col space-y-1") }, { children: hours && hours.length > 0 && (_jsxs(_Fragment, { children: [_jsx("h3", __assign({ className: tw("font-semibold text-[" + theme.text + "]") }, { children: "" + day.charAt(0).toUpperCase() + day.substr(1) }), void 0), hours.map(function (_a) {
                                                    var start = _a.start, end = _a.end;
                                                    return (_jsxs("div", __assign({ className: tw("space-x-4") }, { children: [_jsx("span", __assign({ className: tw(Styles.rounded, "bg-[" + theme.secondary + "] px-4 py-2 text-[" + theme.primary + "]") }, { children: dayjs(new Date())
                                                                    .set('hour', Number(start.split(':')[0]))
                                                                    .set('minute', Number(start.split(':')[1]))
                                                                    .format('h:mm a') }), void 0), _jsx("span", __assign({ className: tw(Styles.rounded, "bg-[" + theme.secondary + "] px-4 py-2 text-[" + theme.primary + "]") }, { children: dayjs(new Date())
                                                                    .set('hour', Number(end.split(':')[0]))
                                                                    .set('minute', Number(end.split(':')[1]))
                                                                    .format('h:mm a') }), void 0)] }), void 0));
                                                })] }, void 0)) }), void 0));
                                }) }), void 0)] }), void 0))] }), void 0), _jsxs(ModalFooter, { children: [_jsx(Button, __assign({ primary: true, onClick: function () {
                            window.location.href = "https://maps.google.com/?ll=" + place.attributes.geoPoint.latitude + "," + place.attributes.geoPoint.longitude;
                        } }, { children: "Open on Google Maps" }), void 0), _jsx(Button, __assign({ onClick: function () { return modal.hide(); } }, { children: "Close" }), void 0)] }, void 0)] }, void 0));
};
export default PlaceModal;
