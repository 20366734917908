var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useEffect, useMemo, useState, } from 'react';
import { Theme } from '../types/theme';
import useReduction from './useReduction';
export var DefaultSettings = {
    // Notifications
    allowNotifications: false,
    newAnnouncements: false,
    postUpdates: false,
    placeUpdates: false,
    osPlayerId: {},
    locationServices: false,
    theme: Theme.System.value,
    sessionToken: '',
};
var SettingsContext = createContext(__assign(__assign({}, DefaultSettings), { initialized: false, update: function () { }, useDefaults: function () { }, useSaved: function () { } }));
export var useSettings = function () { return useContext(SettingsContext); };
export function SettingsProvider(_a) {
    var children = _a.children, _lsKey = _a.lsKey, _b = _a.settings, _settings = _b === void 0 ? DefaultSettings : _b;
    var memoizedSettings = useMemo(function () { return _settings; }, [_settings]);
    var lsKey = useMemo(function () { return _lsKey; }, [_lsKey]);
    var _c = useReduction(memoizedSettings), settingsState = _c[0], setSettingsState = _c[1];
    var _d = useState(false), initialized = _d[0], setInitialized = _d[1];
    var setSettings = useCallback(function (settings) {
        return setSettingsState(__assign(__assign({}, DefaultSettings), settings));
    }, [setSettingsState]);
    var getValidSettings = function (settings) {
        if (!settings) {
            return DefaultSettings;
        }
        return __assign(__assign({}, DefaultSettings), settings);
    };
    var getSavedSettings = useCallback(function () {
        var savedSettings = window.localStorage.getItem(lsKey);
        return getValidSettings(JSON.parse(savedSettings !== null && savedSettings !== void 0 ? savedSettings : '{}'));
    }, [lsKey]);
    var _persist = function (settings) {
        window.localStorage.setItem(lsKey, JSON.stringify(settings));
    };
    var update = useCallback(function (updates, persist) {
        if (persist === void 0) { persist = false; }
        return setSettings(__assign(__assign({}, settingsState), updates));
    }, [settingsState, setSettings]);
    var useDefaults = useCallback(function () { return setSettings(DefaultSettings); }, [setSettings]);
    var useSaved = useCallback(function () { return setSettings(getSavedSettings()); }, [setSettings, getSavedSettings]);
    useEffect(function () {
        setSettingsState(getSavedSettings());
        setInitialized(true);
    }, []);
    useEffect(function () {
        if (initialized && window) {
            _persist(settingsState);
        }
    }, [initialized, lsKey, settingsState]);
    return (_jsx(SettingsContext.Provider, __assign({ value: __assign(__assign({}, settingsState), { initialized: initialized, update: update, useDefaults: useDefaults, useSaved: useSaved }) }, { children: children }), void 0));
}
export default useSettings;
