var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useReducer } from 'react';
export var useReduction = function (initialState) {
    return useReducer(function (prev, updated) { return (__assign(__assign({}, prev), updated)); }, initialState);
};
export default useReduction;
